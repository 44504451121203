import { useMemo } from 'react';
import { REACT_APP_CF_HELP_EMAIL } from 'config/env';
import { Button, CustomButton, Text } from 'components';
import styles from './header.module.scss';
import { useBreakpoints, useTranslation } from 'hooks';
import { Colors } from 'types/color.type';
import { useFoodProviderContext } from 'context/food-provider-context';
import { useAppContext } from 'context/app-context';
import { useModalContext } from 'context/modal-context';
import Status from 'constants/Status';
import Lang from 'constants/Locale';
import { ModalType } from 'constants/ModalType';
import { getIconDimensions } from 'helpers/foodProvider';

type ModalConfigOptions = {
  modalKey: string;
  handleAccept: () => void;
  confirmButtonText: string;
  cancelButtonText: string;
  acceptButtonClassName: string;
  t: any;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
};

const getModalBody = (modalKey: string, t: any, isMobile: boolean) => (
  <>
    <Text
      type={isMobile ? 'body' : 'bodyLg'}
      color={Colors.CFDarkGrey}
      className={styles.modalSubmitBody}
    >
      {t(`${modalKey}.body`)}
    </Text>{' '}
    <a
      href={`mailto:${REACT_APP_CF_HELP_EMAIL}`}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.modalSubmitBodyLink}
    >
      {REACT_APP_CF_HELP_EMAIL}
    </a>
    {'.'}
  </>
);

const getModalConfig = ({
  modalKey,
  handleAccept,
  confirmButtonText,
  cancelButtonText,
  acceptButtonClassName,
  t,
  setShowModal,
  isMobile,
}: ModalConfigOptions) => {
  const titleKey = t(`${modalKey}.title`);
  const title = `${titleKey}?`;

  return {
    type: ModalType.Generic,
    title,
    body: getModalBody(modalKey, t, isMobile),
    handleClose: () => setShowModal(false),
    handleAccept,
    confirmButtonText,
    cancelButtonText,
    cancelButtonClassName: styles.modalSubmitButton,
    acceptButtonClassName,
    footerClassName: styles.modalSubmitFooter,
    reverseButtonOrder: !isMobile,
    showCloseButton: !isMobile,
  };
};

const FoodProviderFormHeader = () => {
  const { t } = useTranslation();
  const { locale } = useAppContext();
  const { isMobile } = useBreakpoints();

  const { setShowModal, setAndShowModal } = useModalContext();

  const {
    selectedFoodProvider,

    foodProviderToCreate,
    setFoodProviderToCreate,

    isEditing,
    setIsEditing,
  } = useFoodProviderContext();

  const dimensions = useMemo(() => getIconDimensions(isMobile), [isMobile]);

  const currentPropertyName =
    selectedFoodProvider?.property?.[Lang.en]?.fields?.WebPropertyName?.[Lang.en];
  const currentFloorName = selectedFoodProvider?.unit?.[Lang.en]?.fields?.FNO?.[Lang.en]?.sys?.name;

  const showSubmitModal = () =>
    setAndShowModal(
      getModalConfig({
        modalKey: 'submit_food_provider_modal',
        handleAccept: () => console.log('Handle Submit TBD'),
        confirmButtonText: t('submit_food_provider_modal.submit'),
        cancelButtonText: t(
          isMobile ? 'generic_modal.close_label' : 'submit_food_provider_modal.continue_editing'
        ),
        acceptButtonClassName: styles.modalSubmitButton,
        t,
        setShowModal,
        isMobile,
      })
    );

  const showDeleteModal = () =>
    setAndShowModal(
      getModalConfig({
        modalKey: 'delete_food_provider_modal',
        handleAccept: () => {
          if (foodProviderToCreate) {
            // Deletes the unsaved draft
            setFoodProviderToCreate(null);
            setShowModal(false);
            setIsEditing(false);
          }
          console.log('Handle Delete TBD');
        },
        confirmButtonText: t('delete_food_provider_modal.delete'),
        cancelButtonText: t('cancel'),
        acceptButtonClassName: styles.modalDeleteButton,
        t,
        setShowModal,
        isMobile,
      })
    );

  return (
    <div className={styles.header}>
      <div>
        <Text
          className={styles.unitTitle}
          tag="h3"
          type="h3"
          color={Colors.MidnightBlue}
          highlightColor={Colors.SkyBlue}
        >
          {selectedFoodProvider?.unit?.[Lang.en]?.fields?.WebStoreName?.[locale] ??
            t('food_provider_view.store_information')}
        </Text>
        <Text className={styles.unitSubtitle} type="h5" color={Colors.MidnightBlue}>
          {currentPropertyName && currentFloorName
            ? `${currentPropertyName} | ${currentFloorName}`
            : t('food_provider_view.property_details')}
        </Text>
      </div>
      <div className={styles.headerWrapper}>
        <Button
          className={styles.headerButton}
          onClick={() => {
            if (!isEditing) {
              setIsEditing(true);
            } else {
              showSubmitModal();
            }
          }}
        >
          {t(isEditing ? 'food_provider_edit.submit' : 'edit')}
        </Button>
        {isEditing && (selectedFoodProvider?.status as Status) !== Status.Published && (
          <CustomButton
            variant="cf-text"
            alt=""
            text=""
            iconName="save"
            iconHeight={dimensions.save.height}
            iconWidth={dimensions.save.width}
            className={styles.headerButtonDraft}
          />
        )}
        <CustomButton
          variant="cf-text"
          alt=""
          text=""
          iconName="trash"
          iconHeight={dimensions.trash.height}
          iconWidth={dimensions.trash.height}
          className={styles.headerButtonDelete}
          onClick={() => showDeleteModal()}
        />
      </div>
    </div>
  );
};

export default FoodProviderFormHeader;
