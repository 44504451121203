import { useEffect, useState } from 'react';
import cx from 'classnames';
import { TextInput, Text, CustomButton, SingleSelect } from 'components';
import Lang from 'constants/Locale';
import styles from './order-option-card.module.scss';
import { useFoodProviderContext } from 'context/food-provider-context';
import { Colors } from 'types/color.type';
import { useBreakpoints, useTranslation } from 'hooks';
import { InputCheckbox } from 'components/form/input-checkbox';
import { useAppContext } from 'context/app-context';
import { SingleSelectOption } from 'components/single-select/single-select';

export enum OrderOptionCardType {
  Unselected = 'unselected', // A service hasn't been selected for this option
  Existing = 'existing', // This option already existed
  New = 'new', // This is a new option that will be created
}

const OrderOptionCard = ({
  orderOption,
  textInputClassName,
  onDelete,
  onSelect,
  error: propError,
  type = OrderOptionCardType.Unselected,
  isReadOnly = false,
  setIsEditing,
}: OrderOptionCardProps) => {
  const {
    selectedOrderOptions,
    setSelectedOrderOptions,
    orderOptionsToCreate,
    setOrderOptionsToCreate,
    orderMethodOptions,
    orderServiceOptions,
  } = useFoodProviderContext();
  const { t } = useTranslation(['common', 'error']);
  const { isMobile } = useBreakpoints();
  const { locale } = useAppContext();

  const orderServiceId =
    orderOption?.orderService?.[Lang.en]?.sys?.id ?? orderOption?.orderService?.sys?.id;
  const orderServiceName =
    orderOption?.orderService?.[Lang.en]?.fields?.Name?.[Lang.en] ??
    orderOption?.orderService?.name?.[Lang.en];

  const defaultSelectedOption =
    orderServiceId && orderServiceName ? { value: orderServiceId, label: orderServiceName } : null;

  const [selectedOption, setSelectedOption] = useState<SingleSelectOption | null>(
    defaultSelectedOption
  );
  const [availableOptions, setAvailableOptions] = useState<SingleSelectOption[]>([]);

  const [combinedOptions, setCombinedOptions] = useState<SingleSelectOption[]>(
    defaultSelectedOption ? [defaultSelectedOption] : []
  );

  const error = propError ?? (type === OrderOptionCardType.Unselected && !selectedOption);

  const serviceLinks = orderOption?.orderUrl?.[Lang.en]?.fields?.WebLink;

  const textFields: { lang: Lang; label: string; value: string; editingFieldTitle?: string }[] = [
    {
      lang: Lang.en,
      label: t('food_provider_edit.service_link_title', {
        language: t('english'),
      }),
      value: serviceLinks?.[Lang.en] ?? '',
    },
    {
      lang: Lang.fr,
      label: t('food_provider_edit.service_link_title', {
        language: t('french'),
      }),
      value: serviceLinks?.[Lang.fr] ?? '',
      editingFieldTitle: t('food_provider_edit.optional_field', {
        fieldValue: t('food_provider_edit.service_link_title', {
          language: t('french'),
        }),
      }),
    },
  ];

  useEffect(() => {
    const updatedAvailableOptions = orderServiceOptions?.reduce((acc, currentOrderService) => {
      const isServiceAvailable =
        !selectedOrderOptions.some(
          (selectedOrderOption) =>
            currentOrderService?.sys?.id ===
            (selectedOrderOption?.orderService?.[Lang.en]?.sys?.id ??
              selectedOrderOption?.orderService?.sys?.id)
        ) &&
        !orderOptionsToCreate.some(
          (orderOptionToCreate) =>
            currentOrderService?.sys?.id === orderOptionToCreate?.orderService?.sys?.id
        );

      return isServiceAvailable
        ? [
            ...acc,
            {
              value: currentOrderService?.sys?.id,
              label: currentOrderService?.name?.[Lang.en],
            },
          ]
        : acc;
    }, []);

    setAvailableOptions(updatedAvailableOptions);
  }, [selectedOrderOptions, orderOptionsToCreate]);

  useEffect(() => {
    setCombinedOptions([
      ...new Set([...(selectedOption ? [selectedOption] : []), ...availableOptions]),
    ]);
  }, [selectedOption, availableOptions]);

  const updateOrderOption = (updatedOrderOption: any) => {
    switch (type) {
      case OrderOptionCardType.Existing:
        setSelectedOrderOptions([
          ...selectedOrderOptions.filter(
            (filteredOrderOption) => filteredOrderOption?.sys?.id !== orderOption?.sys?.id
          ),

          updatedOrderOption,
        ]);
        break;
      case OrderOptionCardType.New:
        setOrderOptionsToCreate([
          ...orderOptionsToCreate.filter(
            (filteredOrderOption) =>
              filteredOrderOption?.orderService?.sys?.id !==
              updatedOrderOption?.orderService?.sys?.id
          ),
          updatedOrderOption,
        ]);
        break;
      default:
        break;
    }
  };

  const updateServiceLink = (language: Lang, newValue: string) => {
    const updatedOrderOption = orderOption;

    updatedOrderOption.orderUrl[Lang.en].fields.WebLink[language] = newValue;

    updateOrderOption(updatedOrderOption);
  };

  const updateOrderMethods = (orderMethodId: string, isChecked: boolean) => {
    const updatedOrderOption = orderOption;

    if (isChecked) {
      updatedOrderOption.orderMethods =
        updatedOrderOption.orderMethods?.filter?.(
          (filteredOrderMethodId: string) => filteredOrderMethodId !== orderMethodId
        ) ?? [];
    } else {
      updatedOrderOption.orderMethods = [
        ...(updatedOrderOption?.orderMethods ?? []),
        orderMethodId,
      ];
    }

    updateOrderOption(updatedOrderOption);
  };

  return (
    <div className={styles.orderOptionCard} key={`order-option-card-${orderServiceName ?? 'new'}`}>
      {isReadOnly ? (
        <div className={styles.readOnly}>
          <img
            className={styles.readOnlyImage}
            src={
              orderServiceOptions?.find(
                (orderService) =>
                  orderService.sys.id === orderOption?.orderService?.[Lang.en]?.sys?.id
              )?.imageUrl?.[Lang.en]
            }
            alt=""
            height={isMobile ? 24 : undefined}
            width={isMobile ? undefined : 130}
          />
          <div className={styles.readOnlyFields}>
            {orderMethodOptions && orderMethodOptions?.length > 0 && (
              <div className={styles.readOnlyMethod}>
                {orderMethodOptions
                  ?.filter((orderMethodOption) =>
                    orderOption?.orderMethods?.includes(orderMethodOption?.sys?.id)
                  )
                  ?.map((orderMethodOption) => (
                    <div
                      className={styles.readOnlyMethodItem}
                      key={`order-method-${orderMethodOption?.sys?.id}`}
                    >
                      {orderMethodOption?.method?.[locale]}
                    </div>
                  ))}
              </div>
            )}

            {textFields.map((field) => (
              <div key={`field-wrapper-${field.label}`} className={styles.readOnlyLink}>
                {field.value ? (
                  <>
                    <Text type="body" color={Colors.CFDarkGrey} className={styles.readOnlyLinkText}>
                      {field.label}
                    </Text>
                    <Text
                      type="body"
                      color={Colors.CFBlue}
                      className={cx(styles.readOnlyLinkText, styles.readOnlyLinkTextEllipsis)}
                    >
                      {field.value}
                    </Text>
                  </>
                ) : (
                  <CustomButton
                    text={field.label}
                    variant="cf-text"
                    alt=""
                    iconName="plusBlue"
                    iconHeight="24"
                    iconWidth="24"
                    onClick={() => setIsEditing?.(isReadOnly)}
                    className={styles.readOnlyButton}
                    textCustomStyle={styles.readOnlyButtonText}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div
            className={cx(styles.orderOptionService, { [styles.orderOptionServiceError]: error })}
          >
            <div className={styles.orderOptionServiceSelect}>
              <Text type="body" color={Colors.CFDarkGrey}>
                {t('food_provider_edit.service_title')}
              </Text>
              <SingleSelect
                customStyled
                error={error}
                errorText={t('error:food_provider_form.order_service.MISSING')}
                name={`select-order-option-${orderServiceId}`}
                value={selectedOption?.value ?? ''}
                options={combinedOptions}
                placeholder={{
                  value: '',
                  label: t('food_provider_edit.service_dropdown_placeholder'),
                }}
                onChange={(e: any) => {
                  const newSelection = availableOptions?.find(
                    (availableOption) => availableOption.value === e.target.value
                  );

                  if (type !== OrderOptionCardType.Unselected && newSelection) {
                    setSelectedOption(newSelection);
                  }

                  if (onSelect) {
                    onSelect(e);
                  }
                }}
              />
            </div>
            <CustomButton
              variant="cf-text"
              alt=""
              iconName="trashRed"
              iconHeight="26"
              iconWidth="18"
              className={cx(styles.orderOptionServiceButton, {
                [styles.orderOptionServiceButtonError]: error,
              })}
              onClick={onDelete}
            />
          </div>
          {type !== OrderOptionCardType.Unselected && (
            <>
              <div className={styles.method}>
                <Text type="body" color={Colors.CFDarkGrey}>
                  {t('food_provider_edit.order_method_title')}
                </Text>
                {orderOption?.orderService && orderMethodOptions && (
                  <div className={styles.methodOptionWrapper}>
                    {orderMethodOptions?.map((orderMethodOption) => {
                      const name = orderMethodOption?.method?.[locale];
                      const id = orderMethodOption?.sys?.id;
                      const isChecked = !!orderOption?.orderMethods?.includes(id);
                      return (
                        <button
                          key={`checkbox-${name}`}
                          className={styles.checkboxWrapper}
                          onClick={() => updateOrderMethods(id, isChecked)}
                        >
                          <InputCheckbox
                            className={styles.checkbox}
                            checked={isChecked}
                            readOnly
                            tabIndex={-1}
                          />
                          <Text type="body">{name}</Text>
                        </button>
                      );
                    })}
                  </div>
                )}
              </div>
              {textFields.map((field) => {
                const { lang, label, value } = field;
                return (
                  <div key={`service-link-${lang}`}>
                    <Text type="body" color={Colors.CFDarkGrey}>
                      {field.editingFieldTitle ?? label}
                    </Text>
                    <TextInput
                      id={`service-link-input-${lang}`}
                      type="text"
                      value={value}
                      label={label}
                      className={textInputClassName}
                      onChange={(e) => {
                        updateServiceLink(lang, e.target.value);
                      }}
                    />
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
    </div>
  );
};

interface OrderOptionCardProps {
  orderOption?: any;
  textInputClassName?: string;
  onDelete?: () => unknown;
  onSelect?: (e: any) => unknown;
  error?: boolean;
  type?: OrderOptionCardType;
  isReadOnly?: boolean;
  setIsEditing?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default OrderOptionCard;
