import {
  REACT_APP_ANALYTICS_ENABLE_DEBUG_MODE,
  REACT_APP_CONSENT_AUTO_BLOCK_URL,
  REACT_APP_CONSENT_SDK_STUB_ID,
  REACT_APP_CONSENT_SDK_STUB_URL,
  REACT_APP_ENABLE_GOOGLE_ANALYITCS,
  REACT_APP_GOOGLE_ANALYTICS_TAG_ID,
} from 'config/env';
import Locale, { HtmlLang } from 'constants/Locale';
import EntryQuery from 'constants/Entry';

declare global {
  interface Window {
    gtag: Gtag.Gtag;
    dataLayer: any[];
  }
}

export function gtag(..._args: any) {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
  }
}

export enum LoginStatus {
  SUCCESS = 'Success',
  SIGN_IN_ERROR = 'SignInError',
  ERROR = 'Error',
}

export enum SearchCategory {
  OFFER = 'Offer',
  FOOD_PROVIDER = 'FoodProvider',
}

function loadGoogleAnalytics() {
  // Set default consent to 'denied' as a placeholder
  gtag('consent', 'default', {
    ad_storage: 'denied', // Enables storage (such as cookies) related to advertising.
    ad_user_data: 'denied', // Sets consent for sending user data related to advertising to Google.
    ad_personalization: 'denied', // Sets consent for personalized advertising.
    analytics_storage: 'denied', // Enables storage (such as cookies) related to analytics e.g. visit duration.
    functionality_storage: 'granted', // Enables storage that supports the functionality of the website or app, e.g., language settings.
    personalization_storage: 'denied', // Enables storage related to personalization, e.g., video recommendations.
    security_storage: 'granted', // Enables storage related to security, such as authentication functionality, fraud prevention, and other user protections.
    wait_for_update: 500, // in ms. Allows for the integration of asynchronous consent management platforms (CMPs) by applying a delay to tags firing based on consent
  });

  const script: HTMLScriptElement = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${REACT_APP_GOOGLE_ANALYTICS_TAG_ID}`;
  script.async = true;

  document.head.appendChild(script);

  // Initialize Google Analytics
  gtag('js', new Date());
  gtag('config', REACT_APP_GOOGLE_ANALYTICS_TAG_ID, {
    debug_mode: REACT_APP_ANALYTICS_ENABLE_DEBUG_MODE,
  });
}

export function loadCookieConsentScripts(locale: Locale) {
  const autoBlockScript: HTMLScriptElement = document.createElement('script');
  autoBlockScript.src = REACT_APP_CONSENT_AUTO_BLOCK_URL;
  autoBlockScript.type = 'text/javascript';
  document.head.appendChild(autoBlockScript);

  const sdkStubScript: HTMLScriptElement = document.createElement('script');
  sdkStubScript.id = 'sdkStubScript';
  sdkStubScript.src = REACT_APP_CONSENT_SDK_STUB_URL;
  sdkStubScript.type = 'text/javascript';

  let scriptLocale: Locale | HtmlLang = locale;
  if (locale === Locale.en) {
    scriptLocale = HtmlLang.en;
  }

  sdkStubScript.dataset.language = scriptLocale;

  sdkStubScript.dataset.domainScript = REACT_APP_CONSENT_SDK_STUB_ID;

  document.head.appendChild(sdkStubScript);
}
export default class GoogleAnalyticsInitializer {
  private initialized = false;

  constructor(locale: Locale) {
    if (!this.initialized && REACT_APP_ENABLE_GOOGLE_ANALYITCS && typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer ?? [];
      window.gtag = gtag;
      loadGoogleAnalytics();
      loadCookieConsentScripts(locale);
      this.initialized = true;
    }
  }

  private sendEvent(
    eventName: Gtag.EventNames,
    eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
  ) {
    if (REACT_APP_ENABLE_GOOGLE_ANALYITCS && this.initialized) {
      if (window.dataLayer) {
        if (!window.dataLayer.find((item) => item[0] === 'consent' && item[1] === 'default')) {
          console.warn(
            'Consent settings are not initialized. Consider initializing them before sending events.'
          );
        }
        gtag('event', eventName, eventParams);
      } else {
        console.error('Google Analytics is not initialized. Make sure the script is loaded.');
      }
    }
  }

  public sendLoginEvent(status: LoginStatus) {
    this.sendEvent('login', {
      login_status: status,
      method: 'Okta',
    });
  }

  public sendSearchEvent(searchTerm: string, category?: SearchCategory) {
    this.sendEvent('search', {
      search_term: searchTerm,
      search_category: category,
    });
  }

  public sendTabClickEvent(tab: EntryQuery) {
    this.sendEvent('screen_view', {
      screen_name: tab,
    });
  }

  public sendViewOfferListEvent(
    tabName: EntryQuery,
    searchTerm: string,
    total: number,
    currentPage: number
  ) {
    this.sendEvent('view_item_list', {
      event_label: tabName,
      tab_name: tabName,
      search_term: searchTerm,
      item_total: total,
      current_page: currentPage,
    });
  }
}
