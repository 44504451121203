import Locale, { HtmlLang } from 'constants/Locale';
import Paths from 'constants/Paths';
import { useAppContext } from 'context/app-context';
import useBreakPoints from 'hooks/useBreakpoints';
import MobileHeader from './mobile.header';
import DesktopHeader from './desktop.header';
import NavItemTypes from 'constants/NavItemTypes';
import { useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { useAuthContext } from 'context/auth-context';
import { useModalContext } from 'context/modal-context';
import useTranslation from 'hooks/useTranslation';
import { ModalType } from 'constants/ModalType';
import { REACT_APP_CF_HELP_EMAIL } from 'config/env';

function Header({ displayNav }: HeaderProps) {
  const { oktaAuth } = useOktaAuth();
  const { locale, setLocale } = useAppContext();
  const { isMobile } = useBreakPoints();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { setAndShowModal, setShowModal } = useModalContext();
  const { isUserMgmt, homeRedirect } = useAuthContext();

  const showHelpModal = () =>
    setAndShowModal({
      type: ModalType.Generic,
      title: t('help_title'),
      body: (
        <>
          {t('help_body')}
          <br />
          <a href={`mailto:${REACT_APP_CF_HELP_EMAIL}`} target="_blank" rel="noopener noreferrer">
            {REACT_APP_CF_HELP_EMAIL}
          </a>
          <br />
          <a href={t('help_modal.learn_more_url')} target="_blank" rel="noopener noreferrer">
            {t('help_modal.learn_more')}
          </a>
        </>
      ),
      handleClose: () => setShowModal(false),
    });

  let navItems = [
    { value: 'my_profile', type: NavItemTypes.link, to: Paths.profile },
    { value: 'help', type: NavItemTypes.button, onClick: showHelpModal },
    { value: 'sign_out', type: NavItemTypes.button, onClick: async () => oktaAuth.signOut() },
  ];

  if (isUserMgmt) {
    navItems = [{ value: 'users', type: NavItemTypes.link, to: Paths.userManagement }, ...navItems];
  } else {
    navItems = [
      { value: 'offers', type: NavItemTypes.link, to: Paths.browseOffersPage },
      { value: 'food_and_drink', type: NavItemTypes.link, to: Paths.foodProvidersPage },
      ...navItems,
    ];
  }

  let localeToToggle: Locale;
  let htmlLocaleToToggle: HtmlLang;
  if (locale === Locale.en) {
    localeToToggle = Locale.fr;
    htmlLocaleToToggle = HtmlLang.fr;
  } else {
    localeToToggle = Locale.en;
    htmlLocaleToToggle = HtmlLang.en;
  }

  const matchingLocaleEntry = Object.entries(Locale).find(([_key, val]) => val === localeToToggle);

  const navLocaleDisplayValue = matchingLocaleEntry ? matchingLocaleEntry[0].toUpperCase() : 'EN';

  const toggleLocale = () => {
    setLocale(localeToToggle, true);
    document.documentElement.lang = htmlLocaleToToggle;
  };

  const navItemsWithActive = navItems.map((item) => ({
    ...item,
    active: item.to ? pathname.includes(item.to) : false,
  }));

  if (isMobile) {
    return (
      <MobileHeader
        navItems={navItemsWithActive}
        locale={navLocaleDisplayValue}
        toggleLocale={toggleLocale}
        displayNav={displayNav}
        homeRedirect={homeRedirect}
      />
    );
  } else {
    return (
      <DesktopHeader
        navItems={navItemsWithActive}
        locale={navLocaleDisplayValue}
        toggleLocale={toggleLocale}
        displayNav={displayNav}
        homeRedirect={homeRedirect}
      />
    );
  }
}

interface HeaderProps {
  displayNav: boolean;
}

export default Header;
