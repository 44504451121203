import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import { Icon, Text } from 'components';
import styles from './single-select.module.scss';
import { Colors } from 'types/color.type';
import useClickOutside from 'hooks/useClickOutside';

function SingleSelect({
  options = [],
  value,
  name,
  onChange,
  disabled = false,
  required = false,
  placeholder,
  className,
  error = false,
  errorText,
  errorClassName,
  customStyled = false,
}: SingleSelectProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number>(-1); // Track focused option index
  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLUListElement>(null);

  // Close dropdown when clicking outside
  useClickOutside(dropdownRef, (_event: Event) => {
    setIsOpen(false);
  });

  const handleSelectClick = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
      setFocusedOptionIndex(-1);
    }
  };

  const handleOptionClick = (optionValue: string) => {
    onChange({ target: { name, value: optionValue } } as any);
    setIsOpen(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!isOpen && (e.key === 'Enter' || e.key === ' ')) {
      // Open dropdown on Enter or Space
      e.preventDefault();
      setIsOpen(true);
    } else if (isOpen) {
      switch (e.key) {
        case 'ArrowDown': // Goes to next option
          e.preventDefault();
          setFocusedOptionIndex((prev) => Math.min(prev + 1, options.length - 1));
          break;
        case 'ArrowUp': // Goes to previous option
          e.preventDefault();
          setFocusedOptionIndex((prev) => Math.max(prev - 1, 0));
          break;
        case 'Enter': // Selects option
        case ' ':
          e.preventDefault();
          if (focusedOptionIndex >= 0) {
            handleOptionClick(options[focusedOptionIndex].value);
          }
          break;
        case 'Escape':
          e.preventDefault();
          setIsOpen(false);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (isOpen && focusedOptionIndex >= 0 && optionsListRef.current) {
      const focusedOption = optionsListRef.current.children[focusedOptionIndex] as HTMLElement;
      focusedOption.focus();
    }
  }, [isOpen, focusedOptionIndex]);

  return (
    <div className={cx(styles.root, { [styles.rootDefault]: !customStyled })} ref={dropdownRef}>
      {customStyled ? (
        <button
          type="button"
          className={cx(
            styles.customDropdown,
            { [styles.open]: isOpen },
            { [styles.disabled]: disabled },
            className
          )}
          onClick={handleSelectClick}
          onKeyDown={handleKeyDown}
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          disabled={disabled}
        >
          <div className={styles.selectedValue}>
            <Text className={cx({ [styles.customDropdownTextDisabled]: disabled })} type="h5">
              {value
                ? options.find((option) => option.value === value)?.label
                : placeholder?.label ?? 'Select...'}
            </Text>
          </div>
          <div className={cx(styles.indicator, { [styles.indicatorError]: error })}>
            <Icon
              name={isOpen ? 'caretBlueUp' : 'caretTriangle'}
              alt=""
              width={isOpen ? '24' : '12'}
              className={cx(styles.icon, {
                [styles.iconOpen]: isOpen,
              })}
            />
          </div>
          {isOpen && (
            <ul
              className={cx(styles.optionsList, { [styles.optionsListError]: error })}
              role="listbox"
              aria-activedescendant={options[focusedOptionIndex]?.value}
              ref={optionsListRef}
              tabIndex={-1}
            >
              {options.map(({ label, value: optionValue }, index) => {
                const isOptionSelected = optionValue === value;
                return (
                  <li
                    key={optionValue}
                    className={cx(styles.option, { [styles.optionSelected]: isOptionSelected })}
                    role="option"
                    aria-selected={isOptionSelected}
                    onClick={() => handleOptionClick(optionValue)}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                  >
                    {isOptionSelected && (
                      <Icon className={styles.iconCheck} name="checkMark" alt="" width={20} />
                    )}
                    <Text
                      className={cx(styles.optionText, {
                        [styles.optionTextSelected]: isOptionSelected,
                      })}
                      type="bodySm"
                      color={Colors.MidnightBlue}
                    >
                      {label}
                    </Text>
                  </li>
                );
              })}
            </ul>
          )}
        </button>
      ) : (
        <>
          <select
            className={cx(
              styles.rmDefault,
              {
                [errorClassName ?? styles.error]: error,
              },
              className
            )}
            onChange={onChange}
            value={value}
            name={name}
            disabled={disabled}
            required={required}
            aria-required={required}
          >
            {placeholder && (
              <option value={placeholder.value} disabled hidden>
                {placeholder.label}
              </option>
            )}
            {options.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>

          <div className={cx(styles.indicator, { [styles.indicatorError]: error })}>
            <Icon name="caretTriangle" alt="dropdown" width="12" />
          </div>
        </>
      )}
      {error && errorText && (
        <div
          className={cx(styles.errorText, { [styles.errorTextCustom]: customStyled })}
          aria-live="assertive"
        >
          {errorText}
        </div>
      )}
    </div>
  );
}

export type SingleSelectOption = {
  label: string;
  value: string;
};

interface SingleSelectProps {
  options: SingleSelectOption[];
  onChange(e: React.ChangeEvent<HTMLSelectElement>): void;
  name: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: SingleSelectOption;
  className?: string;
  error?: boolean;
  errorText?: string;
  errorClassName?: string;
  customStyled?: boolean;
}

export default SingleSelect;
