import cx from 'classnames';
import Status from 'constants/Status';
import styles from './tab-item.module.scss';
import { Text } from 'components';
import { useBreakpoints, useTranslation } from 'hooks';
import { Colors } from 'types/color.type';
import { StateFilterOptions } from 'context/food-provider-context';

interface TabItemProps {
  propertyName?: string;
  floorName?: string;
  unitName?: string;
  status: Status;
  lastUpdated: string;
  isSelected?: boolean;
  onSelect?: () => void;
}

const tabItemStatusMapping = {
  [Status.Published]: StateFilterOptions.Approved,
  [Status.PendingReview]: StateFilterOptions.Pending,
  [Status.Draft]: StateFilterOptions.Draft,
  [Status.Declined]: StateFilterOptions.Declined,
};

const tabItemStatusColor = {
  [Status.Published]: Colors.LightGreen,
  [Status.PendingReview]: Colors.LightYellow,
  [Status.Draft]: Colors.GreyMist,
  [Status.Declined]: Colors.LightRed,
};

const TabItem = ({
  propertyName,
  floorName,
  unitName,
  status,
  lastUpdated,
  isSelected,
  onSelect,
}: TabItemProps) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  return (
    <button
      tabIndex={isSelected ? -1 : 0}
      className={cx(styles.tabItem, { [styles.tabItemSelected]: isSelected })}
      onClick={onSelect}
    >
      <div className={styles.tabItemTop}>
        <Text className={styles.tabItemTopTitle} color={Colors.MidnightBlue} type="h5">
          {propertyName ?? t('food_provider_view.new_draft')}
        </Text>
        <Text
          className={styles.tabItemTopSubtitle}
          color={Colors.MidnightBlue}
          type={isMobile ? 'body' : 'bodySm'}
        >
          {floorName && unitName
            ? `${floorName} · ${unitName}`
            : t('food_provider_view.unselected_property')}
        </Text>
      </div>
      <div className={styles.tabItemBottom}>
        <div
          className={cx(
            styles.tabItemBottomPill,
            styles[`tabItemBottomPill-${tabItemStatusColor[status]}`]
          )}
        >
          <Text className={styles.tabItemBottomPillText} type="allCaps" color={Colors.Black}>
            {t(tabItemStatusMapping[status])}
          </Text>
        </div>
        <Text
          className={styles.tabItemBottomDateText}
          color={Colors.MidnightBlue}
          type="bodySm"
        >{`${t('food_provider_left_panel.updated')}: ${lastUpdated}`}</Text>
      </div>
    </button>
  );
};

export default TabItem;
