import { createContext, useContext, useState, useEffect } from 'react';
import FoodProviderRequester from './foodProvider/foodProviderRequester';
import OfferRequester from './offer/offerRequester';
import { useAuthContext } from 'context/auth-context';

export enum RequesterTypes {
  FoodProvider = 'foodProvider',
  Offer = 'offer',
}

type RequesterType = `${RequesterTypes}`;
type RequesterInstance = FoodProviderRequester | OfferRequester;

const Context = createContext<RequesterInstance | null>(null);

export function RequesterProvider({
  children,
  type,
}: Readonly<{
  children: React.ReactNode;
  type: RequesterType;
}>) {
  const [requester, setRequester] = useState<RequesterInstance | null>(null);
  const { token } = useAuthContext();

  useEffect(() => {
    if (token) {
      if (type === RequesterTypes.FoodProvider) {
        setRequester(new FoodProviderRequester(token));
      } else if (type === RequesterTypes.Offer) {
        setRequester(new OfferRequester(token));
      }
    } else {
      setRequester(null);
    }
  }, [token, type]);

  return <Context.Provider value={requester}>{children}</Context.Provider>;
}

export function useRequester<T extends RequesterInstance>() {
  return useContext(Context) as T;
}
