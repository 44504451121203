/* eslint-disable no-param-reassign */
import { useEffect, useMemo, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import useTranslation from 'hooks/useTranslation';
import Status from 'constants/Status';
import { OffersTable } from 'components';
import EntryQuery from 'constants/Entry';
import useRouter from 'hooks/useRouter';
import OffersContext from 'context/offers-context';

export const OFFER_TYPE_QUERY_KEY = 'offer_type';

const OfferTabs = ({ searchTerm }: OfferTabsProps) => {
  const { t } = useTranslation();
  const { queries, toBrowseOffersPage } = useRouter();
  const offerTypeQuery = queries.get(OFFER_TYPE_QUERY_KEY) as EntryQuery;
  const [tab, setTab] = useState<EntryQuery>();

  const offerStatusTabs = [
    { key: EntryQuery.published, label: Status.Published },
    { key: EntryQuery.review, label: Status.PendingReview },
    { key: EntryQuery.draft, label: Status.Draft },
    { key: EntryQuery.declined, label: Status.Declined },
  ];

  useEffect(() => {
    setTab(
      Object.values(EntryQuery).includes(offerTypeQuery) ? offerTypeQuery : EntryQuery.published
    );
  }, [offerTypeQuery]);

  const providerValue = useMemo(
    () => ({
      onDeleteDraftOffer: () => {
        toBrowseOffersPage(EntryQuery.draft);
      },
      onDuplicateOffer: () => {
        toBrowseOffersPage(EntryQuery.draft);
      },
    }),
    [toBrowseOffersPage]
  );

  return (
    <OffersContext.Provider value={providerValue}>
      <Tab.Container
        activeKey={tab}
        onSelect={(k) => {
          const tab = k as EntryQuery;
          setTab(tab);
        }}
      >
        <Nav variant="tabs">
          {offerStatusTabs.map(({ key }) => (
            <Nav.Item key={key}>
              <Nav.Link tabIndex={tab === key ? 0 : -1} eventKey={key}>
                {t(key)}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>

        <Tab.Content>
          {offerStatusTabs.map(({ key, label }) => (
            <Tab.Pane key={key} eventKey={key}>
              {tab === key && (
                <div tabIndex={0}>
                  <OffersTable type={label} status={key} searchTerm={searchTerm} />
                </div>
              )}
            </Tab.Pane>
          ))}
        </Tab.Content>
      </Tab.Container>
    </OffersContext.Provider>
  );
};

interface OfferTabsProps {
  searchTerm: string;
}

export default OfferTabs;
