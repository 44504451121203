import cx from 'classnames';
import styles from './single-fields.module.scss';
import { useTranslation } from 'hooks';
import { Icon, Text, TextInput } from 'components';
import Lang from 'constants/Locale';
import { useFoodProviderContext } from 'context/food-provider-context';
import { useModalContext } from 'context/modal-context';
import { ModalType } from 'constants/ModalType';
import {
  FieldTypes,
  FormItemLanguageProps,
  FormItemProps,
  ReadOnlyButton,
} from '../food-provider-form';
import { Colors } from 'types/color.type';

type FormFieldProps = {
  languageFields: FormItemLanguageProps;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  type?: FieldTypes;
};

const ReadOnlyValue = ({
  languageFields,
  setIsEditing,
  type = FieldTypes.Text,
}: FormFieldProps) => {
  if (type === FieldTypes.Text) {
    return languageFields.value ? (
      <Text
        type="body"
        color={Colors.CFBlue}
        className={cx(styles.readOnlyLinkText, styles.readOnlyLinkTextEllipsis)}
      >
        {languageFields.value}
      </Text>
    ) : (
      <ReadOnlyButton title={languageFields.label} setIsEditing={setIsEditing} />
    );
  } else if (type === FieldTypes.Image) {
    return languageFields.value ? (
      <img
        alt=""
        src={languageFields.value}
        className={cx(styles.imageDisplay, styles.imageDisplayReadOnly)}
      />
    ) : (
      <button className={styles.imageCTAContainer} onClick={() => setIsEditing(true)}>
        <Icon name="add" alt="" width={'24'} height={'24'} className={styles.imageDisplayIcon} />
        <Text color={Colors.CFBlue}>{languageFields?.cta}</Text>
      </button>
    );
  }
  return <></>;
};

const EditingValue = ({
  languageFields,
  type = FieldTypes.Text,
  handleClick,
}: Omit<FormFieldProps, 'setIsEditing'> & { handleClick?: () => unknown }) => {
  if (type === FieldTypes.Text) {
    return (
      <TextInput
        translationGroup="food_provider_form"
        name="link"
        errorText="MISSING"
        id={languageFields.label}
        type="text"
        value={languageFields.value ?? ''}
        label={languageFields.label}
        className={styles.textInput}
        errorClassName={styles.textInputError}
        onChange={languageFields.onChange}
      />
    );
  } else if (type === FieldTypes.Image) {
    const hasValue = !!languageFields.value;
    return (
      <button
        className={cx(styles.imageEditingContainer, {
          [styles.imageEditingContainerCTA]: !hasValue,
        })}
        onClick={handleClick}
      >
        {hasValue ? (
          <img src={languageFields.value} className={styles.imageDisplay} alt="" />
        ) : (
          <>
            <Icon
              name="add"
              alt=""
              width={'24'}
              height={'24'}
              className={styles.imageDisplayIcon}
            />
            <Text color={Colors.CFBlue}>{languageFields?.cta}</Text>
          </>
        )}
      </button>
    );
  }
  return <></>;
};

const FoodProviderSingleFields = ({ formItem }: { formItem: FormItemProps }) => {
  const { t } = useTranslation();
  const { setShowModal, setAndShowModal } = useModalContext();

  const {
    displayFrenchFields,

    isEditing,
    setIsEditing,
  } = useFoodProviderContext();

  const handleImageModalClick = (title?: string) => {
    setAndShowModal({
      show: true,
      type: ModalType.Image,
      title: title,
      handleClose: () => {
        setShowModal(false);
      },
      handleAccept: () => {
        setShowModal(false);
      },
      confirmButtonText: t('food_provider_view.image.cta'),
    });
  };

  return (
    <>
      {[Lang.en, Lang.fr].map((lang) => {
        const languageFields = formItem[lang];

        const languageHandleClick = () => handleImageModalClick(languageFields.modalTitle);

        return (
          (lang === Lang.en || displayFrenchFields) && (
            <div
              key={`${languageFields.label}-${lang}`}
              className={cx(styles.language, {
                [styles.languageEditing]: isEditing,
                [styles.languageImage]: formItem?.type === FieldTypes.Image,
              })}
            >
              {(isEditing || languageFields.value || formItem?.type === FieldTypes.Image) && (
                <Text type="body" color={Colors.CFDarkGrey} className={styles.readOnlyLinkText}>
                  {languageFields.label}
                </Text>
              )}
              {isEditing ? (
                <EditingValue
                  languageFields={languageFields}
                  type={formItem?.type ?? undefined}
                  handleClick={languageHandleClick}
                />
              ) : (
                <ReadOnlyValue
                  languageFields={languageFields}
                  setIsEditing={setIsEditing}
                  type={formItem?.type ?? undefined}
                />
              )}
            </div>
          )
        );
      })}
    </>
  );
};

export default FoodProviderSingleFields;
