import { useMemo } from 'react';
import styles from './food-provider-sidebar.module.scss';
import { CustomButton, SortFilter, TabItem, Text, TextInput } from 'components';
import { useBreakpoints, useTranslation } from 'hooks';
import { useAppContext } from 'context/app-context';
import { useFoodProviderContext } from 'context/food-provider-context';
import { Colors } from 'types/color.type';
import Status from 'constants/Status';
import Lang from 'constants/Locale';
import { dateFormat, getIconDimensions } from 'helpers/foodProvider';

const FoodProviderSidebar = () => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const {
    foodProviders,
    foodProviderToCreate,
    setFoodProviderToCreate,

    selectedFoodProvider,
    setSelectedFoodProvider,

    searchValue,
    setSearchValue,

    setIsEditing,

    setIncompleteOrderOptionCards,
  } = useFoodProviderContext();

  const { locale } = useAppContext();
  const dimensions = useMemo(() => getIconDimensions(isMobile), [isMobile]);

  return (
    <>
      <div className={styles.titleContainer}>
        <div className={styles.titleContainerText}>
          <Text color={Colors.CFDarkGrey} type="bodyLg">
            {t('food_provider_left_panel.subtitle')}
          </Text>
          <Text color={Colors.MidnightBlue} type="h2">
            {t('food_provider_left_panel.title')}
          </Text>
        </div>
        <CustomButton
          variant="cf-text"
          alt=""
          text={t('food_provider_left_panel.create_button')}
          iconName="add"
          iconHeight={dimensions.add.height}
          iconWidth={dimensions.add.width}
          className={styles.titleButton}
          onClick={() => {
            setFoodProviderToCreate({});
            setSelectedFoodProvider({});
            setIsEditing(true);
          }}
        />
      </div>
      <div className={styles.searchContainer}>
        <TextInput
          id="search"
          type="text"
          value={searchValue}
          label={t('food_provider_left_panel.search_placeholder')}
          leftIcon
          icon={{
            name: 'searchBlack',
            alt: '',
            height: dimensions.searchBlack.height,
            width: dimensions.searchBlack.width,
          }}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className={styles.searchInput}
          placeholder={t('food_provider_left_panel.search_placeholder')}
        />
        <Text color={Colors.CFDarkGrey} type="bodySm" className={styles.searchDescription}>
          {t('food_provider_left_panel.search_help_text')}
        </Text>
      </div>
      <div className={styles.tabsContainer}>
        <SortFilter />

        <div className={styles.tabItemContainer}>
          {foodProviderToCreate && (
            <div key={'food-provider-to-create'}>
              <TabItem
                onSelect={() => console.log('Food Provider to create, onClick TBD')}
                unitName={foodProviderToCreate?.unit?.[Lang.en]?.fields?.WebStoreName?.[locale]}
                propertyName={
                  foodProviderToCreate?.property?.[Lang.en]?.fields?.WebPropertyName?.[Lang.en]
                }
                floorName={foodProviderToCreate?.unit?.[Lang.en]?.fields?.FNO?.[Lang.en]?.sys?.name}
                status={Status.Draft}
                lastUpdated={dateFormat.format(new Date())}
                isSelected={!selectedFoodProvider?.sys?.id && !!foodProviderToCreate}
              />
            </div>
          )}
          {foodProviders.items.map((foodProvider) => {
            return (
              <div key={foodProvider.sys.id}>
                <TabItem
                  onSelect={() => {
                    setIsEditing(false);
                    const selected = foodProviders.items.find(
                      (item) => item?.sys?.id === foodProvider?.sys?.id
                    );
                    setSelectedFoodProvider(selected);
                    setFoodProviderToCreate(null);
                    setIncompleteOrderOptionCards(0);
                  }}
                  unitName={foodProvider.unit[Lang.en].fields.WebStoreName[locale]}
                  propertyName={foodProvider.property[Lang.en].fields.WebPropertyName[Lang.en]}
                  floorName={foodProvider.unit[Lang.en].fields.FNO[Lang.en].sys.name}
                  status={foodProvider.status as Status}
                  lastUpdated={dateFormat.format(new Date(foodProvider.sys.updatedAt))}
                  isSelected={selectedFoodProvider?.sys?.id === foodProvider?.sys?.id}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default FoodProviderSidebar;
