import { ChangeEvent, useEffect, useState } from 'react';
import cx from 'classnames';
import styles from './food-provider-form.module.scss';
import { useTranslation } from 'hooks';
import { AlertBanner, Button, CustomButton, Text } from 'components';
import { useFoodProviderContext } from 'context/food-provider-context';
import Status from 'constants/Status';
import Lang from 'constants/Locale';
import { Colors } from 'types/color.type';
import FoodProviderFormHeader from './header';
import FoodProviderStoreSelect from './store-select';
import FoodProviderSingleFields from './single-fields';
import OrderOptionCards from './order-option-cards';

const MAX_ORDER_OPTIONS = 6;

export enum FieldTypes {
  Image = 'image',
  Text = 'text',
}

export type FormItemLanguageProps = {
  label: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  cta?: string;
  modalTitle?: string;
};

export type FormItemProps = {
  title: string;
  subtitle: string;
  [Lang.en]: FormItemLanguageProps;
  [Lang.fr]: FormItemLanguageProps;
  type?: FieldTypes;
};

export const ReadOnlyButton = ({
  title,
  setIsEditing,
}: {
  title: string;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
  <CustomButton
    text={title}
    variant="cf-text"
    alt=""
    iconName="plusBlue"
    iconHeight="24"
    iconWidth="24"
    onClick={() => setIsEditing(true)}
    className={styles.readOnlyButton}
    textCustomStyle={styles.readOnlyButtonText}
  />
);

const FoodProviderForm = () => {
  const { t } = useTranslation();

  const {
    foodProviderToCreate,

    selectedFoodProvider,
    setSelectedFoodProvider,

    selectedOrderOptions,
    orderOptionsToCreate,

    orderServiceOptions,

    isEditing,

    incompleteOrderOptionCards,
    setIncompleteOrderOptionCards,
  } = useFoodProviderContext();

  const [showUnitSelectors, setShowUnitSelectors] = useState<boolean>(false);

  const totalOrderOptions =
    selectedOrderOptions.length + orderOptionsToCreate.length + incompleteOrderOptionCards;

  useEffect(() => {
    setShowUnitSelectors(!selectedFoodProvider?.sys?.id || !!foodProviderToCreate);
  }, [selectedFoodProvider]);

  const formItems: FormItemProps[] = [
    {
      type: FieldTypes.Image,
      title: t('food_provider_view.image.title'),
      subtitle: t('food_provider_view.image.subtitle'),
      [Lang.en]: {
        label: t('food_provider_view.image.language_title', {
          language: t('english'),
        }),
        value: selectedFoodProvider?.imageUrl?.[Lang.en],
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          console.log(`Image english onChange::e: ${e}`);
          setSelectedFoodProvider({
            ...selectedFoodProvider,
            imageUrl: {
              ...selectedFoodProvider?.imageUrl,
              [Lang.en]: e.target.value,
            },
          });
        },
        cta: t('food_provider_view.image.cta'),
        modalTitle: t('food_provider_image_modal.title', {
          language: t('english'),
        }),
      },
      [Lang.fr]: {
        label: isEditing
          ? t('food_provider_edit.optional_field', {
              fieldValue: t('food_provider_view.image.language_title', {
                language: t('french'),
              }),
            })
          : t('food_provider_view.image.language_title', {
              language: t('french'),
            }),
        value: selectedFoodProvider?.imageUrl?.[Lang.fr],
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          console.log(`Image french onChange::e: ${e}`);
          setSelectedFoodProvider({
            ...selectedFoodProvider,
            imageUrl: {
              ...selectedFoodProvider?.imageUrl,
              [Lang.en]: e.target.value,
            },
          });
        },
        cta: t('food_provider_view.image.cta'),
        modalTitle: t('food_provider_image_modal.title', {
          language: t('french'),
        }),
      },
    },
    {
      title: t('food_provider_view.menu_link.title'),
      subtitle: t('food_provider_view.menu_link.subtitle'),
      [Lang.en]: {
        label: isEditing
          ? t('food_provider_edit.optional_field', {
              fieldValue: t('food_provider_view.menu_link.cta', {
                language: t('english'),
              }),
            })
          : t('food_provider_view.menu_link.cta', {
              language: t('english'),
            }),
        value: selectedFoodProvider?.menuUrl,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setSelectedFoodProvider({
            ...selectedFoodProvider,
            menuUrl: e.target.value,
          });
        },
      },
      [Lang.fr]: {
        label: isEditing
          ? t('food_provider_edit.optional_field', {
              fieldValue: t('food_provider_view.menu_link.cta', {
                language: t('french'),
              }),
            })
          : t('food_provider_view.menu_link.cta', {
              language: t('french'),
            }),
        value: selectedFoodProvider?.menuUrlFrench,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setSelectedFoodProvider({
            ...selectedFoodProvider,
            menuUrlFrench: e.target.value,
          });
        },
      },
    },
    {
      title: t('food_provider_view.reservation_link.title'),
      subtitle: t('food_provider_view.reservation_link.subtitle'),
      [Lang.en]: {
        label: isEditing
          ? t('food_provider_edit.optional_field', {
              fieldValue: t('food_provider_view.reservation_link.cta', {
                language: t('english'),
              }),
            })
          : t('food_provider_view.reservation_link.cta', {
              language: t('english'),
            }),
        value: selectedFoodProvider?.reserveTableUrl,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setSelectedFoodProvider({
            ...selectedFoodProvider,
            reserveTableUrl: e.target.value,
          });
        },
      },
      [Lang.fr]: {
        label: isEditing
          ? t('food_provider_edit.optional_field', {
              fieldValue: t('food_provider_view.reservation_link.cta', {
                language: t('french'),
              }),
            })
          : t('food_provider_view.reservation_link.cta', {
              language: t('french'),
            }),
        value: selectedFoodProvider?.reserveTableUrlFrench,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setSelectedFoodProvider({
            ...selectedFoodProvider,
            reserveTableUrlFrench: e.target.value,
          });
        },
      },
    },
  ];

  return (
    <>
      <FoodProviderFormHeader />
      <div className={cx(styles.form, { [styles.formReadonly]: !isEditing })}>
        {showUnitSelectors && (
          <div className={styles.item}>
            <div className={styles.itemHeading}>
              <Text type="h5" color={Colors.MidnightBlue}>
                {t('food_provider_view.property.title')}
              </Text>
            </div>
            <div className={styles.itemFields}>
              <FoodProviderStoreSelect />
            </div>
          </div>
        )}
        {formItems?.map((formItem) => (
          <div key={`form-item-${formItem.title}`} className={styles.item}>
            <div className={styles.itemHeading}>
              <Text type="h5" color={Colors.MidnightBlue}>
                {formItem.title}
              </Text>
              <Text type="body" color={Colors.CFDarkGrey} className={styles.itemHeadingSubtitle}>
                {formItem.subtitle}
              </Text>
            </div>
            <div
              className={cx(styles.itemFields, {
                [styles.itemFieldsImage]: formItem?.type === FieldTypes.Image,
              })}
            >
              <FoodProviderSingleFields formItem={formItem} />
            </div>
          </div>
        ))}
        <div className={styles.item}>
          <div className={styles.itemHeading}>
            <Text type="h5" color={Colors.MidnightBlue}>
              {t('food_provider_view.order_options.title')}
            </Text>
            <Text type="body" color={Colors.CFDarkGrey}>
              {t('food_provider_view.order_options.subtitle')}
            </Text>
          </div>
          <div className={styles.itemFields}>
            <OrderOptionCards textInputClassName={styles.textInput} />
          </div>
          {isEditing &&
            orderServiceOptions &&
            totalOrderOptions < MAX_ORDER_OPTIONS &&
            totalOrderOptions < orderServiceOptions?.length && (
              <>
                <Button
                  variant="outline"
                  onClick={() => setIncompleteOrderOptionCards(incompleteOrderOptionCards + 1)}
                >
                  {t('food_provider_edit.add_service_cta')}
                </Button>
                <AlertBanner
                  displayIcon
                  title="You can only have a maximum of 6 online ordering services"
                  ariaLabel="You can only have a maximum of 6 online ordering services"
                />
              </>
            )}
        </div>
      </div>
      {isEditing && (
        <div className={styles.bottom}>
          <div className={styles.bottomLine} />
          <div className={styles.bottomButtons}>
            {(selectedFoodProvider?.status as Status) !== Status.Published && (
              <Button size="stretch" variant="outline">
                {t('food_provider_edit.save_draft')}
              </Button>
            )}

            <Button size="stretch">{t('food_provider_edit.submit')}</Button>
          </div>
        </div>
      )}
    </>
  );
};

export default FoodProviderForm;
