import axios from 'axios';
import type OfferType from 'types/offer.type';
import type OfferCategory from 'types/offerCategory.type';
import type OfferData from 'types/offerData.type';
import { toFormData } from 'helpers/form';
import { OFFER_API_ENDPOINT, CATEGORIES_API_ENDPOINT } from 'config/env';
import { FetchMethods } from 'constants/Service';

class OfferService {
  static async saveAsDraft(id: string, offerData?: OfferData): Promise<OfferType> {
    const form = offerData ? toFormData(offerData) : new FormData();

    if (id) {
      form.append('id', id);
    }

    const res = await axios.post(`${OFFER_API_ENDPOINT}/draft`, form);
    return res.data as OfferType;
  }

  static async submitForApproval(id: string, offerData?: OfferData): Promise<OfferType> {
    const form = offerData ? toFormData(offerData) : new FormData();

    if (id) {
      form.append('id', id);
    }

    const res = await axios.post(`${OFFER_API_ENDPOINT}/review`, form);
    return res.data as OfferType;
  }

  static async deleteOffer(id: string) {
    const response = await fetch(`${OFFER_API_ENDPOINT}/${id}`, {
      method: FetchMethods.Delete,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      throw new Error(`${response.status} ${response.statusText}`);
    }
  }

  static async getById(id: string, bilingual = false): Promise<OfferType> {
    const res = await axios.get(`${OFFER_API_ENDPOINT}/${id}`, {
      params: { bilingual },
    });
    return res.data as OfferType;
  }

  static async getUserOffers(): Promise<OfferType[]> {
    const res = await axios.get(OFFER_API_ENDPOINT);
    return res.data as OfferType[];
  }

  static async getCategories(): Promise<OfferCategory[]> {
    const res = await axios.get(CATEGORIES_API_ENDPOINT);
    return res.data as OfferCategory[];
  }
}

export default OfferService;
