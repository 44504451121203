import cx from 'classnames';
import { Text } from 'components';
import styles from './tabs.module.scss';
import { StateFilterOptions, useFoodProviderContext } from 'context/food-provider-context';
import { capitalizeFirstLetter } from 'helpers/stringFormatter';
import { Colors } from 'types/color.type';

const Tabs = () => {
  const { appliedState, setAppliedState } = useFoodProviderContext();

  return (
    <div className={styles.tabContainer}>
      {Object.values(StateFilterOptions).map((state) => (
        <button
          onClick={() => {
            if (state !== appliedState) setAppliedState(state);
          }}
          key={`tab-${state}`}
          className={cx(styles.tab, { [styles.tabActive]: appliedState === state })}
        >
          <Text type="h5" color={Colors.MidnightBlue} className={styles.tabText}>
            {capitalizeFirstLetter(state)}
          </Text>
        </button>
      ))}
    </div>
  );
};

export default Tabs;
