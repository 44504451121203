import { useTranslation } from 'hooks';
import { useFoodProviderContext } from 'context/food-provider-context';
import OrderOptionCard, {
  OrderOptionCardType,
} from 'components/order-option-card/order-option-card';
import { ReadOnlyButton } from '../food-provider-form';

const OrderOptionCards = ({ textInputClassName }: { textInputClassName: string }) => {
  const { t } = useTranslation();

  const {
    selectedOrderOptions,
    setSelectedOrderOptions,
    orderOptionsToCreate,
    setOrderOptionsToCreate,
    orderOptionsToDelete,
    setOrderOptionsToDelete,

    orderServiceOptions,

    isEditing,
    setIsEditing,

    incompleteOrderOptionCards,
    setIncompleteOrderOptionCards,
  } = useFoodProviderContext();

  return (
    <>
      {selectedOrderOptions?.map((orderOption, index) => {
        return (
          <OrderOptionCard
            setIsEditing={setIsEditing}
            isReadOnly={!isEditing}
            type={OrderOptionCardType.Existing}
            key={`order-option-${orderOption?.sys?.id ?? index}`}
            orderOption={orderOption}
            textInputClassName={textInputClassName}
            onDelete={() => {
              // Add to the to-be-deleted bucket
              setOrderOptionsToDelete([...orderOptionsToDelete, orderOption]);

              // Remove from existing bucket
              setSelectedOrderOptions(
                selectedOrderOptions.filter((_, filterIndex) => filterIndex !== index)
              );
            }}
            onSelect={(e) => {
              const updatedOrderOption = orderOption;
              updatedOrderOption.orderService = orderServiceOptions?.find(
                (orderService) => orderService.sys.id === e.target.value
              );

              setSelectedOrderOptions([
                ...selectedOrderOptions.filter((_, filterIndex) => filterIndex !== index),
                updatedOrderOption,
              ]);
            }}
          />
        );
      })}
      {isEditing &&
        orderOptionsToCreate?.map((orderOption, index) => {
          return (
            <OrderOptionCard
              type={OrderOptionCardType.New}
              key={`order-option-to-create-${orderOption?.sys?.id ?? index}`}
              orderOption={orderOption}
              textInputClassName={textInputClassName}
              onDelete={() =>
                setOrderOptionsToCreate(
                  orderOptionsToCreate.filter((_, filterIndex) => filterIndex !== index)
                )
              }
              onSelect={(e) => {
                const updatedOrderOption = orderOption;
                updatedOrderOption.orderService = orderServiceOptions?.find(
                  (orderService) => orderService.sys.id === e.target.value
                );

                setOrderOptionsToCreate([
                  ...orderOptionsToCreate.filter((_, filterIndex) => filterIndex !== index),
                  updatedOrderOption,
                ]);
              }}
            />
          );
        })}
      {isEditing &&
        Array.from({ length: incompleteOrderOptionCards }).map((_, index) => (
          <OrderOptionCard
            key={`unselected-order-option-${index}`}
            onSelect={(e) => {
              setOrderOptionsToCreate([
                ...orderOptionsToCreate,
                {
                  orderService: orderServiceOptions?.find(
                    (orderService) => orderService.sys.id === e.target.value
                  ),
                },
              ]);
              setIncompleteOrderOptionCards(incompleteOrderOptionCards - 1);
            }}
            onDelete={() => setIncompleteOrderOptionCards(incompleteOrderOptionCards - 1)}
          />
        ))}
      {!isEditing && selectedOrderOptions?.length <= 0 && orderOptionsToCreate?.length <= 0 && (
        <ReadOnlyButton
          title={t('food_provider_view.order_options.cta')}
          setIsEditing={setIsEditing}
        />
      )}
    </>
  );
};

export default OrderOptionCards;
