import cx from 'classnames';
import styles from './gallery.module.scss';
import { Button } from 'components';
import { useTranslation } from 'hooks';

const defaultGallery = [
  '/images/offer-default/offer_image_1.jpg',
  '/images/offer-default/offer_image_2.jpg',
  '/images/offer-default/offer_image_3.jpg',
  '/images/offer-default/offer_image_1_fr.jpg',
  '/images/offer-default/offer_image_2_fr.jpg',
  '/images/offer-default/offer_image_3_fr.jpg',
];

export async function linkToFile(link: string, name: string) {
  const blob = await fetch(link).then((image) => image.blob());
  const file = new File([blob], name, {
    type: blob.type,
  });
  return file;
}

const Gallery = ({ galleryItems = defaultGallery, draftValue, setDraftValue }: GalleryProps) => {
  const { t } = useTranslation();

  const getGalleryImageName = (idx: number) => `[RP] Gallery image ${idx}`;

  return (
    <ul className={styles.gallery}>
      {galleryItems?.map((image, key) => (
        <li key={getGalleryImageName(key)}>
          <Button
            onClick={async () => {
              const file = await linkToFile(image, getGalleryImageName(key));
              setDraftValue?.(file);
            }}
            label={t('image_picker.select_gallery_image', { index: key + 1 })}
            className={cx(
              {
                [styles.active]: draftValue?.name === getGalleryImageName(key),
              },
              styles.galleryItem
            )}
            variant="outline"
            type="button"
          >
            <img
              className={styles.galleryImg}
              alt={t('image_picker.select_gallery_image')}
              src={image}
              width="240"
              height="120"
            />
          </Button>
        </li>
      ))}
    </ul>
  );
};

interface GalleryProps {
  galleryItems?: string[];
  draftValue?: File;
  setDraftValue?: React.Dispatch<React.SetStateAction<File | undefined>>;
}

export default Gallery;
