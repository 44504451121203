import cx from 'classnames';
import styles from './text-input.module.scss';
import { Icon } from 'components';
import { IconProps } from 'components/rp-icon/rp-icon';
import React from 'react';
import useTranslation from '../../hooks/useTranslation';

const TextInput = ({
  id,
  type,
  value,
  onChange,
  onBlur,
  placeholder,
  label,
  name,
  error = false,
  errorText,
  icon,
  leftIcon,
  onIconButtonClick,
  min,
  max,
  onKeyDown,
  disabled = false,
  required = false,
  ariaDescribedBy,
  className,
  errorClassName,
  translationGroup,
}: TextInputProps) => {
  const { t } = useTranslation(['common', 'error']);

  const errorMsg = t(`error:${translationGroup ?? 'form'}.${name}.${errorText}`);
  const errorAria = error ? `, ${t('error')} ${errorMsg}` : '';

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <input
          id={id}
          className={cx(
            styles.root,
            {
              [errorClassName ?? styles.error]: error,
              [styles.leftIcon]: icon && leftIcon,
              [styles.rightIcon]: icon && !leftIcon,
            },
            className
          )}
          min={min}
          max={max}
          type={type}
          name={name}
          value={value}
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
          onBlur={(e) => {
            if (onBlur) onBlur(e);
          }}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          aria-label={`${label}${errorAria}`}
          required={required}
          aria-required={required}
          disabled={disabled}
          aria-describedby={ariaDescribedBy}
        />
        {(() => {
          if (icon && onIconButtonClick) {
            return (
              <button
                className={cx(styles.iconButton, { [styles.iconButton_left]: leftIcon })}
                onClick={(e) => {
                  e.preventDefault();
                  onIconButtonClick();
                }}
              >
                <Icon {...icon} />
              </button>
            );
          } else if (icon) {
            return (
              <span className={cx(styles.icon, { [styles.icon_left]: leftIcon })}>
                <Icon {...icon} />
              </span>
            );
          }
          return null;
        })()}
      </div>
      {error && errorText && (
        <div className={styles.errorText} aria-live="assertive">
          {errorMsg}
        </div>
      )}
    </div>
  );
};

interface TextInputProps {
  type: 'number' | 'text' | 'password' | 'date' | 'time' | 'datetime-local';
  value: string;
  label: string;
  id?: string;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(e: React.FocusEvent<HTMLInputElement, Element>): void;
  name?: string;
  error?: boolean;
  errorText?: string;
  placeholder?: string;
  icon?: IconProps;
  leftIcon?: boolean;
  onIconButtonClick?(): void;
  min?: string;
  max?: string;
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  disabled?: boolean;
  required?: boolean;
  ariaDescribedBy?: string;
  className?: string;
  errorClassName?: string;
  translationGroup?: string;
}

export default TextInput;
