import IconUrlMapping from 'constants/IconUrlMap';

export const isAllSelected = (properties: string[] | undefined) =>
  properties?.length === 1 && properties?.[0] === 'all';

export const handleAllSelection = (
  isAllCurrentlySelected: boolean,
  setProperties: (props: string[]) => void
) => {
  if (!isAllCurrentlySelected) {
    setProperties(['all']);
  }
};

export const handleIndividualSelection = (
  newKey: string,
  properties: string[],
  setProperties: (props: string[]) => void,
  propertyFilteringOptions: Record<string, string> | null
) => {
  if (properties.includes(newKey)) {
    // Remove from list
    const removedList = properties.filter((option) => option !== newKey);

    setProperties(removedList.length === 0 ? ['all'] : removedList);
  } else {
    // Add to list
    const addedList = [...properties, newKey];
    setProperties(
      addedList.length === Object.keys(propertyFilteringOptions ?? {}).length - 1
        ? ['all']
        : addedList
    );
  }
};

export const handleCheckboxOptionChange = (
  newKey: string,
  properties: string[],
  setProperties: (props: string[]) => void,
  propertyFilteringOptions: Record<string, string> | null
) => {
  const isAllCurrentlySelected = isAllSelected(properties);

  if (newKey === 'all') {
    handleAllSelection(isAllCurrentlySelected, setProperties);
  } else if (isAllCurrentlySelected) {
    setProperties([newKey]);
  } else {
    handleIndividualSelection(newKey, properties, setProperties, propertyFilteringOptions);
  }
};

type IconDimension = {
  width: string;
  height: string;
};

type IconDimensionKeys = Extract<
  keyof typeof IconUrlMapping,
  'add' | 'searchBlack' | 'save' | 'trash'
>;

type IconDimensionsMap = {
  [key in IconDimensionKeys]: IconDimension;
};

export const getIconDimensions = (isMobile: boolean): IconDimensionsMap => ({
  add: {
    width: isMobile ? '20' : '18',
    height: isMobile ? '20' : '18',
  },
  searchBlack: {
    width: '16',
    height: '16',
  },
  save: {
    width: isMobile ? '16' : '18',
    height: isMobile ? '16' : '18',
  },
  trash: {
    width: isMobile ? '18' : '20',
    height: isMobile ? '20' : '22',
  },
});

export const dateFormat = new Intl.DateTimeFormat('default', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});
