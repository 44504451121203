import { useState } from 'react';
import cx from 'classnames';
import { Icon, RadioButton, Text } from 'components';
import GenericModal from '../GenericModal';
import styles from './SortFilterModal.module.scss';
import useTranslation from 'hooks/useTranslation';
import {
  AccordionInputType,
  AccordionType,
  SortOptions,
  StateFilterOptions,
  useFoodProviderContext,
} from 'context/food-provider-context';
import { InputCheckbox } from 'components/form/input-checkbox';
import { handleCheckboxOptionChange } from 'helpers/foodProvider';

interface SortAccordionProps {
  title: string;
  inputType?: AccordionInputType;
  accordionType: AccordionType;
  selectedOptionKey?: string | string[];
  onOptionChange?: (value: any) => unknown;
  options: Record<any, string> | null;
  containerClassName?: string;
  keyToDisplayFirst?: string;
}

function SortFilterAccordion({
  title,
  options,
  inputType = AccordionInputType.RadioButtons,
  accordionType,
  selectedOptionKey,
  onOptionChange,
  containerClassName,
  keyToDisplayFirst,
}: Readonly<SortAccordionProps>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const orderedKeys = keyToDisplayFirst
    ? [keyToDisplayFirst, ...Object.keys(options ?? {}).filter((key) => key !== keyToDisplayFirst)]
    : Object.keys(options ?? {});

  return (
    <div className={cx(containerClassName, { [styles.accordionContainer]: isOpen })}>
      <button className={styles.accordionHeader} onClick={() => setIsOpen(!isOpen)}>
        <Text type="body">{title}</Text>
        <Icon name={isOpen ? 'chevronUp' : 'chevronDown'} alt="" width={20} height={28} />
      </button>
      {isOpen && (
        <div className={styles.accordionBody}>
          {!!options &&
            orderedKeys.map((optionKey) => {
              const optionValue = options[optionKey];

              const isSelected = Array.isArray(selectedOptionKey)
                ? selectedOptionKey.includes(optionKey)
                : selectedOptionKey === optionKey;

              return (
                <button
                  className={styles.accordionBodyItem}
                  key={`accordion-${accordionType}-option-${optionKey}`}
                  onClick={() => onOptionChange?.(optionKey)}
                >
                  {inputType === AccordionInputType.RadioButtons ? (
                    <RadioButton
                      className={styles.radioButton}
                      containerClassName={styles.radioButtonContainer}
                      checked={isSelected}
                      value={optionKey}
                      readOnly
                      tabIndex={-1}
                    />
                  ) : (
                    <InputCheckbox
                      className={styles.checkbox}
                      type={inputType}
                      name={accordionType}
                      value={optionKey}
                      checked={isSelected}
                      readOnly
                      tabIndex={-1}
                    />
                  )}
                  <Text type="body">{optionValue}</Text>
                </button>
              );
            })}
        </div>
      )}
    </div>
  );
}

const SortFilterModal = ({ title, onApply, onClear }: SortFilterModalProps) => {
  const { t } = useTranslation();
  const {
    appliedState,
    setAppliedState,
    appliedSort,
    setAppliedSort,
    appliedProperties,
    setAppliedProperties,
    stateOptions,
    sortOptions,
    propertyFilteringOptions,
  } = useFoodProviderContext();

  const [localState, setLocalState] = useState<StateFilterOptions>(appliedState);
  const [localSort, setLocalSort] = useState<SortOptions>(appliedSort);
  const [localProperties, setLocalProperties] = useState<string[]>(appliedProperties);

  const handleApply = () => {
    setAppliedState(localState);
    setAppliedSort(localSort);
    setAppliedProperties(localProperties);

    onApply?.();
  };

  const handleClear = () => {
    setLocalState(StateFilterOptions.All);
    setLocalSort(SortOptions.RecentActivity);
    setLocalProperties(['all']);

    setAppliedState(StateFilterOptions.All);
    setAppliedSort(SortOptions.RecentActivity);
    setAppliedProperties(['all']);

    onClear?.();
  };

  return (
    <GenericModal
      title=""
      show
      handleAccept={handleApply}
      handleCancel={handleClear}
      confirmButtonText={t('apply')}
      cancelButtonText={t('clear')}
      cancelButtonClassName={styles.clearButton}
      bodyClassName={styles.modalBody}
      footerClassName={styles.modalFooter}
      containerClassName={styles.modalContainer}
      closeIconProps={{ width: 14, height: 14 }}
      closeButtonClassName={styles.modalCloseButton}
      reverseButtonOrder
    >
      <div className={styles.modalContentWrapper}>
        <div className={styles.titleWrapper}>
          <Text type="h4">{title ?? t('food_provider_left_panel.filters.filter')}</Text>
        </div>

        <SortFilterAccordion
          accordionType={AccordionType.State}
          title={t('food_provider_left_panel.filters.form_status')}
          options={stateOptions}
          selectedOptionKey={localState}
          onOptionChange={setLocalState}
        />
        <SortFilterAccordion
          accordionType={AccordionType.Sort}
          title={t('food_provider_left_panel.filters.sort_by')}
          options={sortOptions}
          selectedOptionKey={localSort}
          onOptionChange={setLocalSort}
        />
        <SortFilterAccordion
          accordionType={AccordionType.Filter}
          title={t('food_provider_left_panel.filters.filter')}
          options={propertyFilteringOptions}
          inputType={AccordionInputType.Checkboxes}
          selectedOptionKey={localProperties}
          onOptionChange={(newKey) => {
            handleCheckboxOptionChange(
              newKey,
              localProperties,
              setLocalProperties,
              propertyFilteringOptions
            );
          }}
          containerClassName={styles.accordionLast}
          keyToDisplayFirst="all"
        />
      </div>
    </GenericModal>
  );
};

export interface SortFilterModalProps {
  title?: string;
  onApply?: () => void;
  onClear?: () => void;
}

export default SortFilterModal;
