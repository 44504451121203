import axios, { AxiosInstance } from 'axios';
import Status from 'constants/Status';
import { toFormData } from 'helpers/form';
import { EntryCollection } from 'types';

interface RequesterOptions {
  apiToken: string;
  baseURL: string;
}

export type GetItemsParams = {
  status: string;
  skip: number;
  limit: number;
  search?: string;
};

/** T: Entry type. E.g.: Offer, FoodProvider.
 *  K: Entry Base type. Extends Base class. E.g.: OfferBase, FoodProviderBase.
 *  V: Entry data type. E.g.: OfferData, FoodProviderData.
 * */
class Requester<T, K, V> {
  protected axios: AxiosInstance;

  constructor({ baseURL, apiToken }: RequesterOptions) {
    this.axios = axios.create({
      baseURL,
      headers: {
        authorization: `Bearer ${apiToken}`,
        'content-type': 'application/form-data',
      },
    });
  }

  async getItems({ search = '', ...otherParams }: GetItemsParams) {
    const res = await this.axios.get<EntryCollection<K>>('/', {
      params: {
        ...otherParams,
        search,
      },
    });

    return res.data;
  }

  async getById(id: string) {
    const res = await this.axios.get<T>(`/${id}`);
    return res.data;
  }

  async create(data: Partial<V>, status: Status) {
    const form = toFormData({ ...data, status });
    const res = await this.axios.post<{ id: string }>('/', form);
    return res.data;
  }

  async edit(id: string, data: Partial<V>, status: Status) {
    const form = toFormData({ ...data, status });
    const res = await this.axios.put<{ id: string }>(`/${id}`, form);
    return res.data;
  }

  async discard(id: string) {
    const res = await this.axios.delete<T>(`/${id}`);
    return res.data;
  }
}

export default Requester;
