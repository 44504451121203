/* eslint-disable no-useless-computed-key */
import React, { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EntryCollection } from 'types';
import mockFoodProviders from './foodProviders.mock.json';
import mockOrderOptions from './orderOptions.mock.json';
import mockOrderServices from './orderServices.mock.json';
import mockOrderMethods from './orderMethods.mock.json';
import mockProperties from './properties.mock.json';
import mockUnits from './units.mock.json';
import Lang from 'constants/Locale';

export enum AccordionInputType {
  RadioButtons = 'radio',
  Checkboxes = 'checkbox',
}

export enum AccordionType {
  Sort = 'sort',
  Filter = 'filter',
  State = 'state',
}

export interface SortAccordionOption {
  title: string;
  value: string;
}

export enum StateFilterOptions {
  All = 'all',
  Approved = 'approved',
  Pending = 'pending',
  Draft = 'draft',
  Declined = 'declined',
}

export enum SortOptions {
  RecentActivity = 'all',
  Alphabetical = 'asc',
  AlphabeticalReversed = 'desc',
}

type FoodProviderContextProps = {
  appliedState: StateFilterOptions;
  setAppliedState: React.Dispatch<React.SetStateAction<StateFilterOptions>>;

  appliedSort: SortOptions;
  setAppliedSort: React.Dispatch<React.SetStateAction<SortOptions>>;

  appliedProperties: string[];
  setAppliedProperties: React.Dispatch<React.SetStateAction<string[]>>;

  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;

  stateOptions: Record<StateFilterOptions, string> | null;
  sortOptions: Record<SortOptions, string> | null;

  propertyFilteringOptions: Record<string, string> | null;
  availablePropertyOptions: Record<string, string> | null;
  setAvailablePropertyOptions: React.Dispatch<React.SetStateAction<Record<string, string> | null>>;
  availableUnits: any[];
  setAvailableUnits: React.Dispatch<React.SetStateAction<any[]>>;

  orderServiceOptions: any[] | null;
  orderMethodOptions: any[] | null;

  selectedFoodProvider: any;
  setSelectedFoodProvider: React.Dispatch<React.SetStateAction<any>>;

  selectedOrderServices: any[];
  setSelectedOrderServices: React.Dispatch<React.SetStateAction<any[]>>;

  selectedOrderOptions: any[];
  setSelectedOrderOptions: React.Dispatch<React.SetStateAction<any[]>>;

  orderOptionsToCreate: any[];
  setOrderOptionsToCreate: React.Dispatch<React.SetStateAction<any[]>>;

  orderOptionsToDelete: any[];
  setOrderOptionsToDelete: React.Dispatch<React.SetStateAction<any[]>>;

  foodProviders: EntryCollection<any>; // TODO: change 'any' for FoodProvider type during integration

  foodProviderToCreate: any | null;
  setFoodProviderToCreate: React.Dispatch<React.SetStateAction<any | null>>;

  displayFrenchFields: boolean;

  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;

  incompleteOrderOptionCards: number;
  setIncompleteOrderOptionCards: React.Dispatch<React.SetStateAction<number>>;
};

const FoodProviderContext = createContext<FoodProviderContextProps>({
  appliedState: StateFilterOptions.All,
  setAppliedState: () => {},

  appliedSort: SortOptions.RecentActivity,
  setAppliedSort: () => {},

  appliedProperties: ['all'],
  setAppliedProperties: () => {},

  searchValue: '',
  setSearchValue: () => {},

  stateOptions: null,
  sortOptions: null,

  propertyFilteringOptions: null,
  availablePropertyOptions: null,
  setAvailablePropertyOptions: () => {},
  availableUnits: [],
  setAvailableUnits: () => {},

  orderServiceOptions: null,
  orderMethodOptions: null,

  selectedFoodProvider: null,
  setSelectedFoodProvider: () => {},

  selectedOrderServices: [],
  setSelectedOrderServices: () => {},

  selectedOrderOptions: [],
  setSelectedOrderOptions: () => {},

  orderOptionsToCreate: [],
  setOrderOptionsToCreate: () => {},

  orderOptionsToDelete: [],
  setOrderOptionsToDelete: () => {},

  foodProviders: {
    total: 0,
    skip: 0,
    limit: 0,
    items: [],
  },
  foodProviderToCreate: null,
  setFoodProviderToCreate: () => {},

  displayFrenchFields: false,

  isEditing: false,
  setIsEditing: () => {},

  incompleteOrderOptionCards: 0,
  setIncompleteOrderOptionCards: () => {},
});

export function FoodProviderContextProvider({ children }: Readonly<{ children: ReactNode }>) {
  // Sorting and filtering
  const [appliedState, setAppliedState] = useState<StateFilterOptions>(StateFilterOptions.All);
  const [appliedSort, setAppliedSort] = useState<SortOptions>(SortOptions.RecentActivity);
  const [appliedProperties, setAppliedProperties] = useState<string[]>(['all']);

  // Possible options
  const [stateOptions, setStateOptions] = useState<Record<StateFilterOptions, string> | null>(null);
  const [sortOptions, setSortOptions] = useState<Record<SortOptions, string> | null>(null);

  const [propertyFilteringOptions, setPropertyFilteringOptions] = useState<Record<
    string,
    string
  > | null>(null);
  const [availablePropertyOptions, setAvailablePropertyOptions] = useState<Record<
    string,
    string
  > | null>(null);
  const [availableUnits, setAvailableUnits] = useState<any[]>([]);

  const [orderServiceOptions, setOrderServiceOptions] = useState<any[]>([]);
  const [orderMethodOptions, setOrderMethodOptions] = useState<any[]>([]);

  // Selected values
  const [selectedFoodProvider, setSelectedFoodProvider] = useState<any>(null); // Could be existing or a new one
  const [selectedOrderServices, setSelectedOrderServices] = useState<any[]>([]);

  // Order Options: 3 buckets (existing, to create & to delete)
  const [selectedOrderOptions, setSelectedOrderOptions] = useState<any[]>([]);
  const [orderOptionsToCreate, setOrderOptionsToCreate] = useState<any[]>([]);
  const [orderOptionsToDelete, setOrderOptionsToDelete] = useState<any[]>([]);

  // Food providers: 2 buckets (existing & to create)
  const [foodProviders, setFoodProviders] = useState<EntryCollection<any>>({
    // TODO: change 'any' for FoodProvider type during integration
    total: 0,
    skip: 0,
    limit: 0,
    items: [],
  });
  const [foodProviderToCreate, setFoodProviderToCreate] = useState<any>(null);

  const [searchValue, setSearchValue] = useState<string>('');
  const [displayFrenchFields, setDisplayFrenchFields] = useState<boolean>(false);

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (selectedFoodProvider?.sys?.id) {
      if (foodProviderToCreate) setFoodProviderToCreate(null); // Removes the unsaved draft if user clicks on another food provider
    } else {
      setFoodProviderToCreate(selectedFoodProvider);
    }

    // Set the order options for said food provider
    setSelectedOrderOptions([
      ...(mockOrderOptions?.filter(
        (orderOption) =>
          selectedFoodProvider?.orderOptions &&
          orderOption?.sys?.id &&
          selectedFoodProvider.orderOptions.includes(orderOption.sys.id)
      ) ?? []),
    ]);

    setOrderOptionsToCreate([]);
    setOrderOptionsToDelete([]);

    // Set the order services for said food provider
    setSelectedOrderServices([
      ...(mockOrderServices?.filter((orderService) => {
        return (
          orderService?.sys?.id &&
          selectedOrderOptions.some(
            (orderOption) =>
              orderOption?.orderService?.[Lang.en]?.sys?.id &&
              orderService?.sys?.id === orderOption?.orderService?.[Lang.en]?.sys?.id
          )
        );
      }) ?? []),
    ]);

    setIncompleteOrderOptionCards(initialCardNumber);
  }, [selectedFoodProvider]);

  useEffect(() => {
    if (!foodProviderToCreate) {
      // Auto-selects the first food provider once the unsaved draft disappears

      setSelectedFoodProvider(mockFoodProviders?.[0]);
    }
  }, [foodProviderToCreate]);

  useEffect(() => {
    const foodProviders = {
      total: mockFoodProviders.length,
      skip: 0,
      limit: 0,
      items: mockFoodProviders, // TODO: Replace with API call during integration
    };

    const properties =
      mockProperties?.reduce((options, property) => {
        options[property.sys.id] = property.webPropertyName;
        return options;
      }, {} as Record<string, string>) ?? null;

    const units = mockUnits;

    const propertyIdsWithFoodProviders = new Set<string>(
      foodProviders.items.map((fp: any) => fp.property[Lang.en].sys.id)
    );
    const unitsWithoutFoodProviders = units.filter(
      (unit) => !propertyIdsWithFoodProviders.has(unit.property.sys.id)
    );

    const propertiesWithAvailableUnits: Record<string, string> = {};

    Object.keys(properties).forEach((propertyId) => {
      if (unitsWithoutFoodProviders.some((unit) => unit.property.sys.id === propertyId)) {
        propertiesWithAvailableUnits[propertyId] = properties[propertyId];
      }
    });

    setStateOptions({
      [StateFilterOptions.All]: t('food_provider_left_panel.filters.all'),
      [StateFilterOptions.Approved]: t('approved'),
      [StateFilterOptions.Declined]: t('declined'),
      [StateFilterOptions.Pending]: t('pending'),
      [StateFilterOptions.Draft]: t('draft'),
    });

    setSortOptions({
      [SortOptions.RecentActivity]: t('food_provider_left_panel.filters.recent_activity'),
      [SortOptions.Alphabetical]: t('food_provider_left_panel.filters.alphabetical'),
      [SortOptions.AlphabeticalReversed]: t(
        'food_provider_left_panel.filters.alphabetical_reversed'
      ),
    });

    // TODO: Implement call to Contentful to get all properties related to a user
    setPropertyFilteringOptions({
      ['all']: t('food_provider_left_panel.filters.all_properties'),
      ...(properties ?? {}),
    });

    setAvailablePropertyOptions(
      Object.keys(propertiesWithAvailableUnits)?.length > 0 ? propertiesWithAvailableUnits : null
    );
    setAvailableUnits(unitsWithoutFoodProviders?.length > 0 ? unitsWithoutFoodProviders : []);

    setOrderServiceOptions(mockOrderServices);

    setOrderMethodOptions(mockOrderMethods);

    setFoodProviders(foodProviders);

    setSelectedFoodProvider(foodProviders?.items?.[0]);

    setDisplayFrenchFields(true); // TODO: Implement
    setIsEditing(false);
  }, [t]);

  const initialCardNumber =
    selectedFoodProvider?.orderOptions && selectedFoodProvider?.orderOptions.length > 0 ? 0 : 1;

  const [incompleteOrderOptionCards, setIncompleteOrderOptionCards] =
    useState<number>(initialCardNumber);

  // TODO: useEffect to handle applying sort/filter on change of appliedState, appliedSort, appliedProperties

  const sharedState: FoodProviderContextProps = useMemo(
    () => ({
      appliedState,
      setAppliedState,

      appliedSort,
      setAppliedSort,

      appliedProperties,
      setAppliedProperties,

      searchValue,
      setSearchValue,

      stateOptions,
      sortOptions,

      propertyFilteringOptions,
      availablePropertyOptions,
      setAvailablePropertyOptions,
      availableUnits,
      setAvailableUnits,

      orderServiceOptions,
      orderMethodOptions,

      foodProviders,
      foodProviderToCreate,
      setFoodProviderToCreate,

      selectedFoodProvider,
      setSelectedFoodProvider,

      selectedOrderServices,
      setSelectedOrderServices,

      selectedOrderOptions,
      setSelectedOrderOptions,

      orderOptionsToCreate,
      setOrderOptionsToCreate,

      orderOptionsToDelete,
      setOrderOptionsToDelete,

      displayFrenchFields,

      isEditing,
      setIsEditing,

      incompleteOrderOptionCards,
      setIncompleteOrderOptionCards,
    }),
    [
      appliedState,
      appliedSort,
      appliedProperties,
      searchValue,

      stateOptions,
      sortOptions,

      propertyFilteringOptions,
      availablePropertyOptions,
      availableUnits,

      selectedFoodProvider,
      foodProviderToCreate,

      selectedOrderOptions,
      orderOptionsToCreate,
      orderOptionsToDelete,

      isEditing,

      incompleteOrderOptionCards,
    ]
  );

  return (
    <FoodProviderContext.Provider value={sharedState}>{children}</FoodProviderContext.Provider>
  );
}

export function useFoodProviderContext() {
  const context = useContext(FoodProviderContext);
  if (!context) {
    throw new Error('useFoodProviderContext must be used within a FoodProviderProvider');
  }
  return context;
}
