import type { Offer, OfferData, OfferBase } from 'types';
import Status from 'constants/Status';
import { OfferIdResponse } from 'types/offer.type';
import Requester from '../baseRequester';

class OfferRequester extends Requester<Offer, OfferBase, OfferData> {
  constructor(apiToken: string) {
    super({ baseURL: '/api/offer', apiToken });
  }

  // Specifies only Draft and Pending status for this function
  async create(data: Partial<OfferData>, status: Status.Draft | Status.PendingReview) {
    return super.create(data, status);
  }

  // Specifies only Draft and Pending status for this function
  async edit(id: string, data: Partial<OfferData>, status: Status.Draft | Status.PendingReview) {
    return super.edit(id, data, status);
  }

  async duplicate(id: string) {
    const res = await this.axios.post<OfferIdResponse>(`/${id}/duplicate`);

    return res.data;
  }
}

export default OfferRequester;
