import Locale from 'constants/Locale';

/**
 * returns a date string in a user friendly format,
 * examples:
 * - en-CA: Dec. 18, 2021
 * - fr-CA: 17 déc. 2021
 */
export function toDateString(date: string, locale = Locale.en) {
  const d = new Date(date);

  return d.toLocaleDateString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}

/**
 * Returns serialized date string for use in HTMLInputElement
 * @param {Date} date - Date to serialize
 * @returns Serlized date string in the form of `YYYY-MM-DD`
 */
export function getDateValue(date: Date) {
  return date.toISOString().split('T')[0];
}

/**
 * Returns serialized time string for use in HTMLInputElement
 * @param {Date} date - Date to serialize
 * @returns Serlized time string in the form of `HH:MM`
 */
export function getTimeValue(date: Date) {
  const tokens = date.toISOString().split(/[T:]/g);
  return tokens.slice(2, 5).join('');
}

/**
 * Returns the string with the first letter capitalized
 * @param {string} text the text to capitalize
 * @returns The capitalized string
 */
export function capitalizeFirstLetter(text: string): string {
  if (text.length === 0) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
}
