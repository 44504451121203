import React from 'react';
import cx from 'classnames';
import Icon from '../rp-icon/rp-icon';
import imageMap from '../../constants/IconUrlMap';
import styles from './custom-button.module.scss';

export enum CustomButtonVariants {
  Primary = 'cf-primary',
  Secondary = 'cf-secondary',
  Negative = 'cf-negative',
  Text = 'cf-text',
}

export type CustomButtonVariant = `${CustomButtonVariants}`;

const CustomButton = ({
  variant,
  iconName,
  alt,
  text,
  onClick,
  disabled = false,
  customStyle,
  iconWidth,
  iconHeight,
  textCustomStyle,
  id,
  className,
}: CustomButtonProps) => {
  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      className={cx(`btn btn-${variant} ${styles.button} ${customStyle ?? ''}`, className)}
      disabled={disabled}
    >
      <Icon name={iconName} alt={alt} width={iconWidth} height={iconHeight} />
      {text && <span className={`${styles.text} ${textCustomStyle ?? ''}`}>{text}</span>}
    </button>
  );
};

export interface CustomButtonProps {
  variant: CustomButtonVariant;
  iconName: keyof typeof imageMap;
  alt: string;
  text?: string;
  onClick?: React.MouseEventHandler;
  disabled?: boolean;
  customStyle?: string;
  iconWidth?: string;
  iconHeight?: string;
  textCustomStyle?: string;
  id?: string;
  className?: string;
}

export default CustomButton;
