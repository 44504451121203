import { useModalContext } from 'context/modal-context';
import GenericModal from './modals/GenericModal';
import DeclineUserModal from './modals/DeclineUserModal';
import CreateUserModal from './modals/CreateUserModal';
import SortFilterModal from './modals/SortFilterModal';
import AddImageModal from './modals/AddImageModal';
import { ModalType } from 'constants/ModalType';
import { Text } from 'components';
import ChangePasswordModal from './modals/ChangePasswordModal';
import { FC, Fragment } from 'react';
import { GenericModalProviderProps } from 'types/modal.type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const typeToModalComponent: Record<ModalType, FC<any>> = {
  [ModalType.Generic]: GenericModal,
  [ModalType.DenyUser]: DeclineUserModal,
  [ModalType.ChangePassword]: ChangePasswordModal,
  [ModalType.CreateUser]: CreateUserModal,
  [ModalType.SortFilter]: SortFilterModal,
  [ModalType.Image]: AddImageModal,
};

export default function ModalProvider() {
  const { showModal, modalProps } = useModalContext();
  if (!showModal || modalProps === null) return null;

  const { type } = modalProps;
  const Component = typeToModalComponent[type] ?? Fragment;
  if (type === ModalType.Generic) {
    return (
      <GenericModal title="" show {...modalProps}>
        <Text type="bodyLg">{(modalProps as GenericModalProviderProps).body}</Text>
      </GenericModal>
    );
  }

  return <Component {...modalProps} />;
}
