import type { FoodProvider, FoodProviderData, FoodProviderBase } from 'types';
import Status from 'constants/Status';
import Requester from '../baseRequester';

class FoodProviderRequester extends Requester<FoodProvider, FoodProviderBase, FoodProviderData> {
  constructor(apiToken: string) {
    super({ baseURL: '/api/offer', apiToken });
  }

  // Specifies only Draft and Pending status for this function
  async create(data: Partial<FoodProviderData>, status: Status.Draft | Status.PendingReview) {
    return super.create(data, status);
  }

  // Specifies only Draft and Pending status for this function
  async edit(
    id: string,
    data: Partial<FoodProviderData>,
    status: Status.Draft | Status.PendingReview
  ) {
    return super.edit(id, data, status);
  }
}

export default FoodProviderRequester;
