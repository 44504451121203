import { MouseEvent, ReactNode } from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import cx from 'classnames';
import styles from '../../modal.module.scss';
import { Icon, Button } from 'components';
import { useModalContext } from 'context/modal-context';
import useTranslation from 'hooks/useTranslation';
import { toast } from 'react-toastify';
import { Variant } from 'components/button/button';

const ModalButton = ({
  buttonClassName,
  fullWidthButtons,
  handleClick,
  buttonDisabled,
  buttonText,
  variant,
}: ModalButtonProps) => (
  <Button
    className={cx(styles.modalButton, buttonClassName, {
      [styles['modalButton--fullWidth']]: fullWidthButtons,
    })}
    onClick={handleClick}
    disabled={buttonDisabled}
    variant={variant}
  >
    {buttonText}
  </Button>
);

type ModalButtonProps = {
  buttonClassName?: string;
  fullWidthButtons?: boolean;
  handleClick: (e: MouseEvent) => Promise<void> | void;
  buttonDisabled?: boolean;
  buttonText: string;
  variant?: Variant;
};

const CustomModal = ({
  show,
  title,
  handleClose,
  handleAccept,
  children,
  confirmButtonText,
  cancelButtonText,
  props,
  fullWidthButtons = false,
  acceptDisabled = false,
  handleCancel,
  cancelButtonClassName,
  acceptButtonClassName,
  bodyClassName,
  headerClassName,
  footerClassName,
  containerClassName,
  closeAnchorClassName,
  closeButtonClassName,
  closeIconProps,
  reverseButtonOrder = false,
  showCloseButton = true,
}: CustomModalProps) => {
  const { setShowModal } = useModalContext();
  const { t } = useTranslation();

  const handleAcceptWithToasts = async (e: MouseEvent) => {
    if (!handleAccept) return;
    try {
      await handleAccept?.(e);
    } catch (e: unknown) {
      toast.error((e as Error).message);
      setShowModal(false);
    }
  };

  const handleCancelButtonClick = (e: MouseEvent) => {
    if (handleCancel) {
      handleCancel(e);
    } else if (handleClose) {
      handleClose();
    } else {
      setShowModal(false);
    }
  };

  const modalButtonProps = {
    fullWidthButtons,
  };

  return (
    <Modal
      dialogClassName={styles.dialogContainer}
      contentClassName={cx(styles.modalContainer, containerClassName)}
      show={show}
      onHide={handleClose}
      centered
      {...props}
    >
      <Modal.Header className={styles.modalHeaderContainer}>
        <Modal.Title className={cx(styles.modalHeader, headerClassName)}>{title}</Modal.Title>

        {showCloseButton && (
          <div className={cx(styles.closeAnchor, closeAnchorClassName)}>
            <button
              className={cx(styles.closeButton, closeButtonClassName)}
              aria-label={t('generic_modal.close_label')}
              onClick={() => (handleClose ? handleClose() : setShowModal(false))}
            >
              <Icon name="closeBlue" alt="" width="20" height="20" {...closeIconProps} />
            </button>
          </div>
        )}
      </Modal.Header>

      <Modal.Body className={cx(styles.modalBody, bodyClassName)}>{children}</Modal.Body>

      <Modal.Footer
        className={cx(styles.modalFooter, footerClassName, {
          [styles['modalFooter--fullWidth']]: fullWidthButtons,
        })}
      >
        {reverseButtonOrder ? (
          <>
            {!!cancelButtonText && (
              <ModalButton
                {...modalButtonProps}
                buttonClassName={cancelButtonClassName}
                handleClick={handleCancelButtonClick}
                buttonText={cancelButtonText}
                variant={handleCancel ? undefined : 'outline'}
              />
            )}
            {!!handleAccept && (
              <ModalButton
                {...modalButtonProps}
                buttonClassName={acceptButtonClassName}
                handleClick={handleAcceptWithToasts}
                buttonText={confirmButtonText ?? t('continue')}
                buttonDisabled={acceptDisabled}
              />
            )}
          </>
        ) : (
          <>
            {!!handleAccept && (
              <ModalButton
                {...modalButtonProps}
                buttonClassName={acceptButtonClassName}
                handleClick={handleAcceptWithToasts}
                buttonText={confirmButtonText ?? t('continue')}
                buttonDisabled={acceptDisabled}
              />
            )}
            {!!cancelButtonText && (
              <ModalButton
                {...modalButtonProps}
                buttonClassName={cancelButtonClassName}
                handleClick={handleCancelButtonClick}
                buttonText={cancelButtonText}
                variant={handleCancel ? undefined : 'outline'}
              />
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export interface CustomModalProps {
  show: boolean;
  title: ReactNode;
  handleClose?: () => void;
  handleAccept?: (event: MouseEvent) => Promise<void> | void;
  children: ReactNode;
  confirmButtonText?: string;
  cancelButtonText?: string;
  props?: ModalProps;
  fullWidthButtons?: boolean;
  acceptDisabled?: boolean;
  handleCancel?: (event: MouseEvent) => Promise<void> | void;
  cancelButtonClassName?: string;
  acceptButtonClassName?: string;
  bodyClassName?: string;
  headerClassName?: string;
  footerClassName?: string;
  containerClassName?: string;
  closeAnchorClassName?: string;
  closeButtonClassName?: string;
  closeIconProps?: { width?: number; height?: number };
  reverseButtonOrder?: boolean;
  showCloseButton?: boolean;
}

export default CustomModal;
