enum Paths {
  activate = '/activate',
  browseOffersPage = '/offers',
  createOfferPage = '/offers/create',
  forgotPassword = '/forgot-password',
  loginPage = '/',
  profile = '/profile',
  registration = '/registration',
  resetPassword = '/reset-password',
  signupConfirmation = '/signup/confirm',
  signupPage = '/signup',
  tos = '/terms-of-service',
  userManagement = '/users',
  userManagementViewUserInfo = '/users/:id',
  userManagementViewUserRequest = '/users/request/:id',
  foodProvidersPage = '/food-providers',
}

export default Paths;
