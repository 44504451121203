import { useMemo } from 'react';
import cx from 'classnames';
import { Icon, Text } from 'components';
import { Colors } from 'types/color.type';
import styles from './alert-banner.module.scss';
import imageMap from '../../constants/IconUrlMap';

enum AlertBannerTypes {
  Error = 'error',
}

type AlertBannerType = `${AlertBannerTypes}`;

const alertConfig = {
  [AlertBannerTypes.Error]: {
    backgroundColor: Colors.RosePetal,
    borderColor: Colors.HorizonRed,
    icon: 'alert' as keyof typeof imageMap,
  },
};

function AlertBanner({
  id,
  type = AlertBannerTypes.Error,
  title,
  ariaLabel,
  displayIcon,
  ...props
}: Readonly<AlertBannerProps>) {
  const config = useMemo(() => alertConfig[type], [type]);

  return (
    <div
      tabIndex={0}
      className={cx(
        styles.alertContainer,
        styles[`alertContainer-${config.backgroundColor}`],
        styles[`alertContainerBorder-${config.borderColor}`]
      )}
      {...props}
    >
      {displayIcon && (
        <div className={styles.alertIconWrapper}>
          <Icon className={styles.alertIcon} ariaHidden name={config.icon} alt="" />
        </div>
      )}
      <div className={styles.alertTitleWrapper}>
        {title && (
          <Text className={styles.alertTitle} type="h5" tag="span" color={Colors.MidnightBlue}>
            {title}
          </Text>
        )}
      </div>
    </div>
  );
}

interface AlertBannerProps {
  id?: string;
  type?: AlertBannerType;
  title?: string;
  displayIcon?: boolean;
  ariaLabel: string;
}

export default AlertBanner;
