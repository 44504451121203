import React, { MouseEventHandler, useContext } from 'react';
import useTranslation from 'hooks/useTranslation';
import Dropdown from 'react-bootstrap/Dropdown';
import useRouter from 'hooks/useRouter';
import styles from './actions-buttons.module.scss';
import Icon from '../rp-icon/rp-icon';
import OffersContext from 'context/offers-context';
import { useFullPageLoaderContext } from 'context/full-page-loader-context';
import Status from 'constants/Status';
import { useModalContext } from 'context/modal-context';
import { ModalType } from 'constants/ModalType';
import { toast } from 'react-toastify';
import { useRequester } from 'context/requesters';
import OfferRequester from 'context/requesters/offer/offerRequester';

const CustomToggle = React.forwardRef<
  HTMLButtonElement,
  { onClick: MouseEventHandler<HTMLButtonElement>; label: string }
>(function CustomToggle(props, ref) {
  return (
    <button className={styles.resetBtn} ref={ref} onClick={props.onClick} aria-label={props.label}>
      <Icon name="meatBall" alt="more-actions-icon" width="35" height="35" />
    </button>
  );
});

const ActionsButton = ({ offerName = 'Offer', offerId, type }: ActionButtonProps) => {
  const { t } = useTranslation('common');
  const offerReq = useRequester<OfferRequester>();

  const { setAndShowModal, setShowModal } = useModalContext();
  const router = useRouter();
  const ctxOffers = useContext(OffersContext);
  const ctxFullPageLoader = useFullPageLoaderContext();

  const editOffer = () => {
    router.toEditOfferPage(offerId);
  };

  const duplicateOffer = async () => {
    if (!offerReq) return;

    await ctxFullPageLoader.handleLoading(
      offerReq.duplicate(offerId).then((response) => {
        ctxOffers.onDuplicateOffer(response.id);
        toast.success(t('duplicate_successful'));
      })
    );
  };

  const discardOffer = async () => {
    if (!offerReq) return;
    setShowModal(false);
    // Delete Offer
    await ctxFullPageLoader.handleLoading(
      offerReq.discard(offerId).then(() => {
        ctxOffers.onDeleteDraftOffer(offerId);
        toast.success(t('delete_successful'));
      })
    );
  };

  const showWarningModal = () =>
    setAndShowModal({
      type: ModalType.Generic,
      title: t('warning'),
      body: t('warning_delete'),
      handleClose: () => setShowModal(false),
      handleAccept: () => discardOffer(),
      confirmButtonText: t('continue'),
      cancelButtonText: t('cancel'),
    });

  return (
    <>
      <Dropdown drop="left">
        <Dropdown.Toggle
          as={CustomToggle}
          id="dropdown-basic"
          label={t('action_button.label', { offerName })}
        />
        <Dropdown.Menu>
          <Dropdown.Item onClick={editOffer} as="button" aria-label={t('edit_label')}>
            <Icon name="edit" alt="edit icon" />
            <span className={styles['dropdown-item__text']}>{t('edit')}</span>
          </Dropdown.Item>
          {type !== Status.Declined && (
            <Dropdown.Item onClick={duplicateOffer} as="button" aria-label={t('duplicate_label')}>
              <Icon name="duplicate" alt="duplicate icon" />
              <span className={styles['dropdown-item__text']}>{t('duplicate')}</span>
            </Dropdown.Item>
          )}
          {type === Status.PendingReview && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item onClick={showWarningModal}>
                <div className={styles['dropdown-item']}>
                  <Icon name="discard" alt="discard icon" />
                  <span className={styles['dropdown-item__text']}>{t('discard_offer')}</span>
                </div>
              </Dropdown.Item>
            </>
          )}
          {type === Status.Draft && (
            <>
              <Dropdown.Item onClick={showWarningModal}>
                <div className={styles['dropdown-item']}>
                  <Icon name="discard" alt="discard icon" />
                  <span className={styles['dropdown-item__text']}>{t('discard_draft')}</span>
                </div>
              </Dropdown.Item>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export interface ActionButtonProps {
  offerName?: string;
  offerId: string;
  type: string;
}

export default ActionsButton;
