export enum Colors {
  MidnightBlue = 'midnight-blue',
  White = 'white',
  Black = 'black',
  Grey = 'grey',
  LightGrey = 'light-grey',
  CFBlue = 'cf-blue',
  CFRed = 'cf-red',
  AthensGrey = 'athens-grey',
  LiveMediumBlue = 'live-medium-blue',
  GreyMist = 'grey-mist',
  DustyGray = 'dusty-gray',
  BahamaBlue = 'bahama-blue',
  CFDarkGrey = 'cf-dark-grey',
  Concrete = 'concrete',
  BayBlue = 'bay-blue',
  LiveLightBlue = 'live-light-blue',
  HorizonRed = 'horizon-red',
  PaleBlue = 'pale-blue',
  SkyBlue = 'sky-blue',
  RosePetal = 'rose-petal',
  LightGreen = 'light-green',
  LightYellow = 'light-yellow',
  LightRed = 'light-red',
  LightBlue = 'light-blue',
}

type Color = `${Colors}`;

export default Color;
