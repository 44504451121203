import styles from './button.module.scss';
import cx from 'classnames';
import { Link } from 'react-router-dom';

type Theme = 'primary' | 'secondary';
type Size = 'small' | 'large' | 'stretch';
type Type = 'submit' | 'button';
export type Variant = 'solid' | 'outline';

function Button({
  theme = 'primary',
  variant = 'solid',
  size = 'large',
  label,
  children,
  disabled = false,
  type = 'button',
  className,
  onClick,
  href,
}: ButtonProps) {
  const buttonClassName = cx(
    styles.root,
    styles[`theme-${theme}-${variant}`],
    styles[`size-${size}`],
    className
  );

  if (href) {
    return (
      <Link className={buttonClassName} to={href} aria-label={label}>
        {children}
      </Link>
    );
  }

  return (
    <button
      className={buttonClassName}
      type={type}
      disabled={disabled}
      aria-label={label}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
    >
      {children}
    </button>
  );
}

export interface ButtonProps {
  label?: string;
  theme?: Theme;
  variant?: Variant;
  size?: Size;
  type?: Type;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  /* passing a href will automatically run the button into an anchor styled as a button */
  href?: string;
}

export default Button;
